<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Dados dos Contratantes
      <a
        class="button is-medium"
        @click="addContractor()"
      >
        + Adicionar
      </a>
    </h3>

    <div
      v-for="(contractor, index) in mdfe.rodo.infANTT.infContratante"
      :key="index"
      class="columns"
    >
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            CPF/CNPJ do Contratante
          </label>

          <div class="control">
            <input
              v-model="contractor.cpf_cnpj"
              v-mask="['###########', '##############']"
              type="text"
              placeholder="00000000000000"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div
        class="column is-narrow"
        style="align-self: flex-end;"
      >
        <a
          class="button is-medium is-danger"
          @click="removeContractor(index)"
        >
          Remover
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {

  directives: {
    mask
  },
  props: {
    mdfe: {
      type: Object,
      required: true
    },
  },

  methods: {
    addContractor(){
      const contractors = this.mdfe.rodo.infANTT.infContratante;
      this.mdfe.rodo.infANTT.infContratante = [{ cpf_cnpj: '' }, ...contractors];
    },

    removeContractor(_index) {
      this.mdfe.rodo.infANTT.infContratante =
        this.mdfe.rodo.infANTT.infContratante
          .filter((el, index) => index !== _index);
    },
  }
};
</script>
