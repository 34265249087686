<template lang="html">
  <section>
    <CustomerForm
      :nfe="customer_invoice"
      :v="$v"
      :customers="customers"
    />
    <ConsumerForm
      :nfe="customer_invoice"
      :v="$v"
    />
    <ProductForm :nfe="customer_invoice" />
    <AdditionalForm
      :nfe="customer_invoice"
      :operations="operations"
    />

    <div class="control-button">
      <button
        type="submit"
        class="button is-primary is-large"
        @click="handleSubmit"
      >
        Emitir
      </button>
    </div>
  </section>
</template>

<script>
import { isEmpty } from 'lodash';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';

import CustomerForm from './Form/Customer';
import ProductForm from './Form/ProductListCart';
import AdditionalForm from './Form/Additional';
import ConsumerForm from './Form/Consumer';

export default {
  components: {
    CustomerForm,
    ProductForm,
    AdditionalForm,
    ConsumerForm,
  },

  data() {
    return {
      customer_invoice: {
        customer_id: '',
        cpf_cnpj: '',
        name: '',
        total: 0,
        description: '',
        items_attributes: [],
        natOp: 'Venda',
        indPag: '0',
        tPag: '01',
      },

      customers: [],

      operations: [
        'Venda',
        'Vendas Diversas',
        'Transferência',
        'Remessa',
        'Industrialização',
        'Lançamentos Diversos',
        'Consignação',
        'Venda de Entrada Futura',
        'Outros'
      ]
    };
  },

  watch: {
    'customer_invoice.customer_id': function(val) {
      this.customers.find(customer => {
        if (customer.id == val) {
          this.customer_invoice.cpf_cnpj = customer.cpf_cnpj;
          this.customer_invoice.name = customer.name;
        }
      });
    },
  },

  mounted() {
    this.setCustomers();
  },

  validations() {
    if (this.customer_invoice.name) {
      return {
        customer_invoice: {
          cpf_cnpj: { required },
          natOp: { required },
          indPag: { required },
          tPag: { required },
          items_attributes: {
            required,
            $each: {
              product_id: { required },
              amount: { required },
              unit: { required },
              cfop: { required },
              price: { required },
            }
          }
        }
      };
    } else {
      return {
        customer_invoice: {
          cpf_cnpj: {},
          natOp: { required },
          indPag: { required },
          tPag: { required },
          items_attributes: {
            required,
            $each: {
              product_id: { required },
              amount: { required },
              unit: { required },
              cfop: { required },
              price: { required },
            }
          }
        }
      };
    }
  },

  methods: {
    handleSubmit(e) {
      const lastItem = this.customer_invoice.items_attributes.slice(-1)[0];

      if(isEmpty(lastItem.selected)) {
        this.customer_invoice.items_attributes.pop();
      }

      if (this.$v.customer_invoice.$invalid) {
        this.$v.customer_invoice.$touch();
        e.preventDefault();
      } else {
        return this.handleCreateInvoice();
      }
    },

    handleCreateInvoice() {
      const { customer_invoice } = this;
      http.post('/api/customer_invoices', { customer_invoice })
        .then(getData)
        .then(() => Turbolinks.visit('/app/nfces'))
        .catch(() => Promise.reject('FAIL_IN_CREATE_INVOICE'));
    },

    setCustomers() {
      http.get('/api/customers')
        .then(getData)
        .then(data => this.customers = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_CUSTOMERS'));
    },
  }
};
</script>
