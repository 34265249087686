<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações da Carga
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label
            class="label"
            for="xDescServ"
          >
            <abbr title="necessário">*</abbr>
            Descrição do Serviço
          </label>
          <div class="control">
            <input
              id="xDescServ"
              v-model="cte.xDescServ"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="qCarga"
          >
            <abbr title="necessário">*</abbr>
            Quant. da Carga
          </label>
          <div class="control">
            <input
              id="qCarga"
              v-model="cte.infQ.qCarga"
              type="text"
              class="input is-medium"
              placeholder="Ex: 10000"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cte: {
      type: Object,
      required: true,
    }
  },
};
</script>
