<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações da Adicionais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="control">
          <label class="label">Informações complementares de interesse</label>

          <textarea
            v-model="mdfe.compl.infCpl"
            class="textarea"
            placeholder="Informações complementares de interesse"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mdfe: {
      type: Object,
      required: true
    },
  },
};
</script>
