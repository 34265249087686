<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações Gerais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Tipo do CTe
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="cte.tpCTe">
                <option
                  v-for="(tpCTe, index) in tpCTes"
                  :key="index"
                  :value="tpCTe.value"
                >
                  {{ tpCTe.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Tipo de Serviço
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="cte.tpServ">
                <option
                  v-for="(tpServ, index) in tpServs"
                  :key="index"
                  :value="tpServ.value"
                >
                  {{ tpServ.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="cfop"
          >
            <abbr title="necessário">*</abbr>
            CFOP
          </label>
          <div class="control">
            <input
              id="cfop"
              v-model="cte.cfop"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="natOp"
          >
            <abbr title="necessário">*</abbr>
            Natureza
          </label>
          <div class="control">
            <input
              id="natOp"
              v-model="cte.natOp"
              type="text"
              class="input is-medium"
              placeholder="Ex: Transporte"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Cidade de Inicio
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="cte.starts_city_id">
                <option
                  disabled
                  value=""
                >Selecione uma cidade</option>
                <option
                  v-for="(city, index) in cities"
                  :key="index"
                  :value="city.id"
                >
                  {{ city.name_with_state }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Cidade de Fim
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="cte.ends_city_id">
                <option
                  disabled
                  value=""
                >Selecione uma cidade</option>
                <option
                  v-for="(city, index) in cities"
                  :key="index"
                  :value="city.id"
                >
                  {{ city.name_with_state }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Cliente
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="cte.taker_id">
                <option
                  disabled
                  value=""
                >Selecione um cliente</option>
                <option
                  v-for="(customer, index) in customers"
                  :key="index"
                  :value="customer.id"
                >
                  {{ customer.name }} ({{ customer.cpf_cnpj }})
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div
        class="column"
        style="align-self:flex-end;"
      >
        <a
          class="button is-medium"
          @click.prevent="openCustomerModal()"
        >
          + Novo Cliente
        </a>
      </div>
    </div>

    <Modal
      v-if="isCustomerVisible"
      title="Novo cliente"
      :is-visible="isCustomerVisible"
      :close-modal="closeCustomerModal"
      :save-button="addCustomer"
    >
      <CustomerForm ref="customerForm" />
    </Modal>
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import Modal from 'app/v1/src/components/Modal';
import CustomerForm from 'app/v1/src/Customer/New';

export default {

  components: {
    Modal,
    CustomerForm,
  },

  props: {
    cte: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      cities: [],
      customers: [],
      isCustomerVisible: false,

      tpCTes: [
        { value: 0, text: 'Normal' },
        { value: 1, text: 'Complemento de Valores' },
        { value: 2, text: 'Anulação' },
        { value: 3, text: 'Substituição' },
      ],

      tpServs: [
        { value: 6, text: 'Transporte de Pessoas' },
        { value: 7, text: 'Transporte de Valores' },
        // { value: 8, text: 'Excesso de Bagagem' },
      ]
    };
  },

  mounted() {
    this.setCustomers();
    this.setCities();
  },

  methods: {
    setCities() {
      http.get('/api/cities')
        .then(getData)
        .then(data => this.cities = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_CITIES'));
    },

    setCustomers() {
      http.get('/api/customers')
        .then(getData)
        .then(data => {
          this.customers = data;
        })
        .catch(() => Promise.reject('FAIL_IN_FETCH_CUSTOMERS'));
    },

    addCustomer() {
      this.$refs.customerForm.handleSubmit()
        .then((data) => {
          this.customers.push(data);
          this.closeCustomerModal();
        })
        .catch(() => Promise.reject('FAIL_ADD_CUSTOMER'));
    },

    openCustomerModal() {
      this.isCustomerVisible = true;
    },

    closeCustomerModal() {
      this.isCustomerVisible = false;
    },
  }
};
</script>
