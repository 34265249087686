<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações Gerais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Tipo de Emitente
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="mdfe.tpEmit">
                <option
                  v-for="(tpEmit, index) in tpEmits"
                  :key="index"
                  :value="tpEmit.value"
                >
                  {{ tpEmit.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Tipo de Transporte
          </label>


        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="RNTRC"
          >
            RNTRC
          </label>
          <div class="control">
            <input
              v-model="mdfe.rodo.infANTT.RNTRC"
              v-mask="'########'"
              type="text"
              placeholder="00000000"
              class="input is-medium"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Cidade de Carregamento
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="mdfe.starts_city_id">
                <option
                  disabled
                  value=""
                >Selecione uma cidade</option>
                <option
                  v-for="(city, index) in cities"
                  :key="index"
                  :value="city.id"
                >
                  {{ city.name_with_state }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Cidades de Descarregamento
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="mdfe.ends_city_id">
                <option
                  disabled
                  value=""
                >Selecione uma cidade</option>
                <option
                  v-for="(city, index) in cities"
                  :key="index"
                  :value="city.id"
                >
                  {{ city.name_with_state }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="dhIniViagem"
          >
            <abbr title="necessário">*</abbr>
            Data e Hora do Inicio da Viagem
          </label>
          <div class="control">
            <DateTimeInput
              v-model="mdfe.dhIniViagem"
              classes="input is-medium"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import DateTimeInput from "app/v1/src/components/DateTimeInput";

export default {
  directives: {
    mask,
  },

  components: {
    DateTimeInput,
  },

  props: {
    mdfe: {
      type: Object,
      required: true,
    },

    cities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tpEmits: [
        {
          value: 1,
          text: "Prestador de serviço de transporte",
        },
        {
          value: 2,
          text: "Transporte de carga própria",
        },
      ],

     
    };
  },
};
</script>
