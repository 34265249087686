<template lang="html">
  <span class="corrigir-nfe">
    <a
      class="button-invoices"
      title="Carta de Correção (CC-e)"
      @click.prevent="openModal"
    >
      <i class="fa fa-edit invoice-icon" />
    </a>

    <modal
      title="Carta de Correção (CC-e)"
      save-button-text="Emitir CCe"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="handleSubmit"
    >
      <form @submit.prevent="handleSubmit">
        <div class="columns">
          <div class="column">
            <table class="table is-bordered">
              <thead>
                <tr>
                  <th>Grupo</th>
                  <th>Campo</th>
                  <th>Valor</th>
                  <th>Índice do Item</th>
                  <th style="border:none" />
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in cte.correction"
                  :key="index"
                >
                  <td>
                    <input
                      v-model="item.grupoAlterado"
                      type="text"
                      class="input products-table-input"
                    >
                  </td>

                  <td>
                    <input
                      v-model="item.campoAlterado"
                      type="text"
                      class="input products-table-input"
                    >
                  </td>

                  <td>
                    <input
                      v-model="item.valorAlterado"
                      type="text"
                      class="input products-table-input"
                    >
                  </td>

                  <td>
                    <input
                      v-model="item.nroItemAlterado"
                      type="text"
                      class="input products-table-input"
                    >
                  </td>


                  <td style="border:none">
                    <a
                      class="button"
                      title="Remover Item"
                      @click.prevent="removeItem(index)"
                    >
                      <i class="fa fa-times products-table-remove" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <a
              class="button"
              @click.prevent="addItem()"
            >+ Nova Linha</a>
          </div>
        </div>
      </form>
    </modal>
  </span>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import Modal from 'app/v1/src/components/Modal';

export default {

  components: {
    Modal
  },

  props: {
    id: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      isVisible: false,
      cte: {
        correction: []
      },

      schema: {
        grupoAlterado: '',
        campoAlterado: '',
        valorAlterado: '',
        nroItemAlterado: ''
      },
    };
  },

  mounted() {
    this.addItem();
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },

    addItem() {
      this.cte.correction.push(this.generateItemSchema());
    },

    removeItem(index) {
      this.cte.correction.splice(index, 1);
    },

    generateItemSchema() {
      return Object.assign({}, this.schema);
    },

    handleSubmit() {
      const { cte } = this;
      http.post(`/api/ctes/${this.id}/cce`, { cte })
        .then(getData)
        .then(() => {
          this.closeModal();
          this.cte.correction = [];
        })
        .catch(() => Promise.reject('FAIL_IN_CCE_INVOICE'));
    }
  }
};
</script>
