<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Dados do Veículo
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Placa
          </label>

          <div class="control">
            <input
              v-model="mdfe.rodo.veicTracao.placa"
              v-mask="'AAA####'"
              type="tel"
              placeholder="AAA0000"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Tara em KG
          </label>

          <div class="control">
            <input
              v-model="mdfe.rodo.veicTracao.tara"
              type="text"
              placeholder="10"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Tipo de Veiculo
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="mdfe.rodo.veicTracao.tpRod">
                <option
                  v-for="(tpRod, index) in tpRods"
                  :key="index"
                  :value="tpRod.value"
                >
                  {{ tpRod.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Tipo de Carroceria
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="mdfe.rodo.veicTracao.tpCar">
                <option
                  v-for="(tpCar, index) in tpCars"
                  :key="index"
                  :value="tpCar.value"
                >
                  {{ tpCar.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            UF
          </label>

          <div class="control">
            <input
              v-model="mdfe.rodo.veicTracao.UF"
              v-mask="'AA'"
              type="text"
              placeholder="RS"
              class="input is-medium"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask
  },

  props: {
    mdfe: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      tpRods: [{
        value: '01',
        text: 'Truck'
      },{
        value: '02',
        text: 'Toco'
      },{
        value: '03',
        text: 'Cavalo Mecânico'
      },{
        value: '04',
        text: 'VAN'
      },{
        value: '05',
        text: 'Utilitário'
      },{
        value: '06',
        text: 'Outros'
      }],

      tpCars: [{
        value: '00',
        text: 'Não Aplicável'
      },{
        value: '01',
        text: 'Aberta'
      },{
        value: '02',
        text: 'Fechada/Baú'
      },{
        value: '03',
        text: 'Granelera'
      },{
        value: '04',
        text: 'Porta Container'
      },{
        value: '05',
        text: 'Sider'
      }]
    };
  },
};
</script>
