<template lang="html">
  <span class="cancelar-nfe">
    <a
      class="button-invoices"
      title="Cancelar"
      @click.prevent="openModal"
    >
      <i class="fa fa-times invoice-icon" />
    </a>

    <modal
      title="Cancelar Nota Fiscal"
      save-button-text="Cancelar"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="cancelNfe"
    >
      <form @submit.prevent="cancelNfe">
        <div class="field">
          <p class="control">
            <strong>Atenção:</strong> <br> Atualmente o prazo máximo para cancelamento de uma NF-e é de 24 horas, após a emissão da mesma.
          </p>
          <label class="label">Justificativa</label>

          <p class="control">
            <textarea
              v-model="product_invoice.justification"
              class="textarea"
              placeholder="Exemplo: Nota fiscal emitida incorretamente..."
            />
          </p>
        </div>
      </form>
    </modal>
  </span>
</template>

<script>
import { isEmpty } from 'lodash';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import Modal from 'app/v1/src/components/Modal';

export default {

  components: {
    Modal
  },

  props: {
    id: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      isVisible: false,
      product_invoice: {
        justification: ''
      },
    };
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },

    hasJustification() {
      return !isEmpty(this.product_invoice.justification);
    },

    greaterJustification() {
      return this.product_invoice.justification.length > 20;
    },

    cancelNfe() {
      if(this.hasJustification() && this.greaterJustification()) {
        this.handleCancelNfe();
      }
    },

    handleCancelNfe() {
      const { product_invoice } = this;
      http.post(`/api/product_invoices/${this.id}/cancel`, { product_invoice })
        .then(getData)
        .then(() => {
          this.closeModal();
          this.product_invoice.justification = '';
        })
        .catch(() => Promise.reject('FAIL_IN_CANCEL_INVOICE'));
    }
  }
};
</script>
