<template lang="html">
  <span class="cancelar-nfe">
    <a
      class="button-invoices"
      title="Cancelar"
      @click.prevent="openModal"
    >
      <i class="fa fa-times invoice-icon" />
    </a>

    <modal
      title="Cancelar CTe"
      save-button-text="Cancelar"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="handleSubmit"
    >
      <form @submit.prevent="handleSubmit">
        <div class="notification">
          <strong>Atenção:</strong>
          <br>
          Atualmente o prazo máximo para cancelamento de uma CTe é de
          24 horas, após a emissão da mesma.
        </div>

        <div class="field">
          <label class="label">Justificativa</label>

          <div class="control">
            <textarea
              v-model="cte.justification"
              class="textarea"
              placeholder="Exemplo: Nota fiscal emitida incorretamente..."
            />
          </div>
        </div>
      </form>
    </modal>
  </span>
</template>

<script>
import { isEmpty } from 'lodash';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import Modal from 'app/v1/src/components/Modal';

export default {

  components: {
    Modal
  },

  props: {
    id: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      isVisible: false,
      cte: {
        justification: ''
      },
    };
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },

    hasJustification() {
      return !isEmpty(this.cte.justification);
    },

    greaterJustification() {
      return this.cte.justification.length > 20;
    },

    handleSubmit() {
      if(this.hasJustification() && this.greaterJustification()) {
        this.handleCancelNfe();
      }
    },

    handleCancelNfe() {
      const { cte } = this;
      http.post(`/api/ctes/${this.id}/cancel`, { cte })
        .then(getData)
        .then(() => {
          this.closeModal();
          this.cte.justification = '';
        })
        .catch(() => Promise.reject('FAIL_IN_CANCEL_INVOICE'));
    }
  }
};
</script>
