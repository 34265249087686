<template lang="html">
  <section>
    <SupplierForm :nfe="product_invoice" />
    <ProductForm :nfe="product_invoice" />
    <TransportForm :nfe="product_invoice" />
    <refNFForm :nfe="product_invoice" />
    <AdditionalForm
      :nfe="product_invoice"
      :operations="operations"
    />

    <div class="control-button eco-invoice-super-total">
      <button
        type="submit"
        class="button is-primary is-large"
        @click="handleSubmit"
      >
        Emitir
      </button>

      <div class="eco-invoice-total">
        Total Geral: {{ grandTotal | toCurrency }}
      </div>
    </div>
  </section>
</template>

<script>
import { isEmpty } from 'lodash';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';
import { toCurrency } from 'app/v1/src/utils/filters';

import SupplierForm from './Form/Supplier';
import ProductForm from './Form/ProductListCart';
import AdditionalForm from './Form/Additional';
import TransportForm from './Form/Transport';
import refNFForm from './Entry/refNFForm';

export default {

  components: {
    ProductForm,
    SupplierForm,
    AdditionalForm,
    TransportForm,
    refNFForm,
  },

  filters: {
    toCurrency
  },
  data() {
    return {
      product_invoice: {
        invoiceable_id: '',
        invoiceable_type: 'Supplier',
        kind: 'entrace',
        total: 0,
        description: '',
        items_attributes: [],
        natOp: 'Compra de Mercadorias',
        modFrete: '9',
        indPag: '0',
        tPag: '01',
        tpNF: '0',
        vFrete: 0,
        refNF: {
          cUF: '',
          AAMM: '',
          mod: '',
          serie: '',
          nNF: '',
          cpf_cnpj: '',
          IE: '',
        },
        transporta: {
          xNome: '',
          IE: '',
          xEnder: '',
          xMun: '',
          UF: '',
          cpf_cnpj: ''
        }
      },

      operations: [
        'Compra de Mercadorias',
        'Uso ou consumo',
        'Ativo imobilizado',
        'Entradas Diversas',
        'Consignação',
        'Devolução de Mercadoria Recebida para Conserto',
        'Retorno',
        'Remessa de Mercadorias',
        'Remessa para Conserto',
        'Outros',
      ]
    };
  },

  validations: {
    product_invoice: {
      invoiceable_id: { required },
      natOp: { required },
      modFrete: { required },
      items_attributes: {
        required,
        $each: {
          product_id: { required },
          amount: { required },
          unit: { required },
          cfop: { required },
          price: { required },
        }
      }
    }
  },

  computed: {
    productsTotal: function() {
      return this.product_invoice.items_attributes.reduce((prev, item) => {
        return prev + (parseFloat(item.price) * parseFloat(item.amount));
      }, 0);
    },

    grandTotal: function () {
      return parseFloat(this.productsTotal) + parseFloat(this.product_invoice.vFrete);
    },
  },

  methods: {
    handleSubmit(e) {
      const lastItem = this.product_invoice.items_attributes.slice(-1)[0];

      if(isEmpty(lastItem.selected)) {
        this.product_invoice.items_attributes.pop();
      }

      if (this.$v.product_invoice.$invalid) {
        e.preventDefault();
      } else {
        return this.handleCreateInvoice();
      }
    },

    handleCreateInvoice() {
      const { product_invoice } = this;
      http.post('/api/product_invoices', { product_invoice })
        .then(getData)
        .then(() => Turbolinks.visit('/app/nfes/entries'))
        .catch(() => Promise.reject('FAIL_IN_CREATE_INVOICE'));
    },
  }
};
</script>
