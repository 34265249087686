<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações do Cliente (Destinatário)
    </h3>

    <div class="columns">
      <slot />
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>*</abbr>
            Selecione seu cliente
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="nfe.invoiceable_id">
                <option
                  disabled
                  value=""
                >Selecione um cliente</option>
                <option
                  v-for="(customer, index) in customers"
                  :key="index"
                  :value="customer.id"
                >
                  {{ customer.name }} ({{ customer.cpf_cnpj }})
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div
        class="column is-narrow"
        style="align-self:flex-end;"
      >
        <a
          class="button is-medium"
          @click.prevent="openModal()"
        >
          + Novo Cliente
        </a>
      </div>
    </div>

    <Modal
      v-if="isVisible"
      title="Novo cliente"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="addCustomer"
    >
      <NewCustomer ref="NewCustomer" />
    </Modal>
  </div>
</template>

<script>
import Modal from 'app/v1/src/components/Modal';
import NewCustomer from 'app/v1/src/Customer/New';

export default {
  components: {
    Modal,
    NewCustomer,
  },

  props: {
    nfe: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    customers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      isVisible: false,
    };
  },

  methods: {
    addCustomer() {
      this.$refs.NewCustomer.handleSubmit()
        .then(data => {
          this.customers.push(data);
          this.nfe.invoiceable_id = data.id;
          this.closeModal();
        })
        .catch(() => Promise.reject('FAIL_ADD_CUSTOMER'));
    },

    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },
  }
};
</script>
