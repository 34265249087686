import Vue                  from 'vue/dist/vue.esm';
import Vuelidate            from 'vuelidate';
import TurbolinksAdapter    from 'vue-turbolinks';
import VueSelect            from 'vue-cool-select';

import NewNfce              from 'app/v1/src/NFCe/New';
import LogNfce              from 'app/v1/src/NFCe/Log';
import CancelNfce           from 'app/v1/src/NFCe/Cancel';
import DownloadNfce         from 'app/v1/src/NFCe/Download';
import ShareNfce            from 'app/v1/src/NFCe/Share';

import NewCte               from 'app/v1/src/CTe/New';
import LogCte               from 'app/v1/src/CTe/Log';
import CancelCte            from 'app/v1/src/CTe/Cancel';
import CceCte               from 'app/v1/src/CTe/CCe';
import DownloadCte          from 'app/v1/src/CTe/Download';

import NewCteOs             from 'app/v1/src/CTeOS/New';
import LogCteOs             from 'app/v1/src/CTeOS/Log';
import CancelCteOs          from 'app/v1/src/CTeOS/Cancel';
import CceCteOs             from 'app/v1/src/CTeOS/CCe';
import DownloadCteOs        from 'app/v1/src/CTeOS/Download';

import NewMdfe              from 'app/v1/src/MDFe/New';
import LogMdfe              from 'app/v1/src/MDFe/Log';
import CancelMdfe           from 'app/v1/src/MDFe/Cancel';
import ClosureMdfe          from 'app/v1/src/MDFe/Closure';
// import CceMdfe              from 'app/v1/src/MDFe/CCe'
import DownloadMdfe         from 'app/v1/src/MDFe/Download';

import NewNfeDeparture      from 'app/v1/src/NFe/NewDeparture';
import NewNfeDevolution     from 'app/v1/src/NFe/NewDevolution';
import NewNfeEntry          from 'app/v1/src/NFe/NewEntry';
import LogNfe               from 'app/v1/src/NFe/Log';
import CancelNfe            from 'app/v1/src/NFe/Cancel';
import CceNfe               from 'app/v1/src/NFe/CCe';
import DownloadNfe          from 'app/v1/src/NFe/Download';
import ShareNfe             from 'app/v1/src/NFe/Share';

import NewTax               from 'app/v1/src/Taxes/New';
import EditTax              from 'app/v1/src/Taxes/Edit';


Vue.use(Vuelidate);
Vue.use(TurbolinksAdapter);
Vue.use(VueSelect, {
  theme: 'bootstrap'
});


document.addEventListener('turbolinks:load', () => {
  var element = document.getElementById('app-vuejs');
  if (element != null) {
    new Vue({
      el: element,
      components: {
        // MDFe
        NewMdfe,
        LogMdfe,
        CancelMdfe,
        ClosureMdfe,
        // CceMdfe,
        // NFCe
        NewNfce,
        LogNfce,
        CancelNfce,
        ShareNfce,
        // CTe
        NewCte,
        LogCte,
        CancelCte,
        CceCte,
        //CteOs
        NewCteOs,
        LogCteOs,
        CancelCteOs,
        CceCteOs,
        // NFe
        NewNfeDeparture,
        NewNfeDevolution,
        NewNfeEntry,
        LogNfe,
        CancelNfe,
        CceNfe,
        ShareNfe,
        // Taxes
        NewTax,
        EditTax,
      },
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var element = document.getElementById('app-vuejs-nfdownload');
  if (element != null) {
    new Vue({
      el: element,
      components: {
        DownloadNfe,
        DownloadCte,
        DownloadCteOs,
        DownloadNfce,
        DownloadMdfe,
      },
    });
  }
});
