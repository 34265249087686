<template lang="html">
  <div id="app">
    <form @submit.prevent="handleSubmit">
      <GeneralForm :cte="passenger_invoice" />
      <FreightForm :cte="passenger_invoice" />
      <VehicleForm :cte="passenger_invoice" />
      <AdditionalForm :cte="passenger_invoice" />

      <div class="field required">
        <div class="control">
          <label class="label">Observações</label>

          <textarea
            v-model="passenger_invoice.description"
            class="textarea"
            placeholder="Descreva para seu cliente informações que possam ser relevantes para este transporte."
          />
        </div>
      </div>

      <div class="control-button">
        <button
          type="submit"
          class="button is-primary is-large"
        >
          Emitir
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';

import GeneralForm from './Form/General';
import FreightForm from './Form/Freight';
import AdditionalForm from './Form/Additional';
import VehicleForm from './Form/Vehicle';

export default {
  components: {
    GeneralForm,
    FreightForm,
    AdditionalForm,
    VehicleForm,
  },

  data() {
    return {
      passenger_invoice: {
        taker_id: '',
        starts_city_id: '',
        ends_city_id: '',
        tpCTe: 0,
        tpServ: 6,
        modal: '01',
        cfop: '',
        natOp: '',
        vTPrest: 0,
        vRec: 0,
        description: '',
        NroRegEstadual: '',
        TAF: '',
        placa: '',
        RENAVAM: '',
        UF: '',
        tpFretamento: 1,
        dhViagem: '',
        infQ: {
          qCarga: '',
        },
      },
    };
  },

  validations: {
    passenger_invoice: {
      taker_id: { required },
      starts_city_id: { required },
      ends_city_id: { required },
      tpCTe: { required },
      tpServ: { required },
      modal: { required },
      cfop: { required },
      natOp: { required },
      vTPrest: { required },
      vRec: { required },
      infQ: {
        required,
        qCarga: { required },
      }
    }
  },

  methods: {
    handleSubmit(e) {
      if (this.$v.passenger_invoice.$invalid) {
        e.preventDefault();
      } else {
        return this.handleCreate();
      }
    },

    handleCreate() {
      const { passenger_invoice } = this;
      http.post('/api/passenger_invoices', { passenger_invoice })
        .then(getData)
        .then(() => Turbolinks.visit('/app/cteos'))
        .catch(() => Promise.reject('FAIL_IN_CREATE_CTEOS'));
    },
  }
};
</script>
