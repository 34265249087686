<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações do Consumidor
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field-body">
          <div
            class="field"
            :class="{'has-error': v.customer_invoice.cpf_cnpj.$error }"
          >
            <label class="label">CPF/CNPJ</label>

            <div class="control">
              <input
                v-model="nfe.cpf_cnpj"
                type="string"
                class="input is-medium"
              >
            </div>

            <span
              v-if="!v.customer_invoice.cpf_cnpj.required"
              class="help is-danger"
            >
              Não pode ficar em branco.
            </span>
          </div>

          <div class="field">
            <label class="label">Nome</label>

            <div class="control">
              <input
                v-model="nfe.name"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nfe: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },
  },
};
</script>
