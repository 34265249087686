<template>
  <div class="content">
    <h3 class="eco-invoice-title">
      <span>Informações do Transporte</span>
      <span
        v-if="!showMore"
        class="cursor-pointer text-sm text-blue-600 select-none"
        @click="showMore = !showMore"
      >Mostrar mais</span>

      <span
        v-if="showMore"
        class="cursor-pointer text-sm text-blue-600 select-none"
        @click="showMore = !showMore"
      >Mostrar menos</span>
    </h3>


    <div :class="showMoreClass">
      <div class="columns">
        <div class="column">
          <div class="field required">
            <label class="label">
              <abbr>* Modalidade de Frete</abbr>
            </label>

            <div class="control">
              <span class="select is-medium is-fullwidth">
                <select v-model="nfe.modFrete">
                  <option
                    v-for="(freight, index) in freights"
                    :key="index"
                    :value="freight.value"
                  >
                    {{ freight.text }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">Nome da Transportadora</label>

            <div class="control">
              <input
                v-model="nfe.transporta.xNome"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">CNPJ/CPF</label>

            <div class="control">
              <input
                v-model="nfe.transporta.cpf_cnpj"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>


        <div class="column">
          <div class="field">
            <label class="label">IE</label>

            <div class="control">
              <input
                v-model="nfe.transporta.IE"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Endereço</label>

            <div class="control">
              <input
                v-model="nfe.transporta.xEnder"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">Município</label>

            <div class="control">
              <input
                v-model="nfe.transporta.xMun"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">Estado (UF)</label>

            <div class="control">
              <input
                v-model="nfe.transporta.UF"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field required">
            <label class="label">
              <abbr>* Valor do Frete</abbr>
            </label>

            <div class="control">
              <CurrencyInput
                v-model="nfe.vFrete"
                classes="input is-medium"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInput from 'app/v1/src/components/CurrencyInput';

export default {
  components: {
    CurrencyInput
  },

  props: {
    nfe: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      showMore: false,
      freights: [
        { value: '0', text: 'Contratação do frete por conta do remetente (CIF)' },
        { value: '1', text: 'Contratação do frete por conta do destinatário (FOB)' },
        { value: '2', text: 'Contratação do Frete por conta de terceiros' },
        { value: '3', text: 'Transporte próprio por conta do remetente' },
        { value: '4', text: 'Transporte próprio por conta do destinatário' },
        { value: '9', text: 'Sem Ocorrência de Transporte' },
      ],
    };
  },

  computed: {
    showMoreClass: function () {
      return {
        'hidden': !this.showMore
      };
    }
  },
};
</script>
