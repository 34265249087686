<template lang="html">
  <span class="root">
    <a
      class="button-invoices"
      :title="title"
      @click="openModal"
    >
      <span class="icon is-small">
        <i class="fa fa-envelope" />
      </span>
    </a>

    <Modal
      :title="title"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="handleDownload"
      save-button-text="Enviar"
    >
      <div class="notification">
        <p>Adicione abaixo os e-mails para o compartilhamento da nota fiscal com virgula, exemplo:
          <strong>meu@email.com,outro@email.com</strong></p><p /><p>O link para download da nota fiscal será enviado em alguns minutos.</p>
      </div>

      <form @submit.prevent="handleDownload">
        <div
          class="field"
          :class="{'has-error': $v.share.emails.$error}"
        >
          <label class="label is-medium">E-mails</label>
          <div class="control">
            <input
              v-model.trim="share.emails"
              class="input is-medium"
              :class="{'is-danger': $v.share.emails.$error}"
              type="email"
              placeholder="meu@email.com,outro@email.com"
              @blur="$v.share.emails.$touch()"
            >
          </div>

          <span
            v-if="!$v.share.emails.required"
            class="help is-danger"
          >
            Não pode ficar em branco.
          </span>
        </div>
      </form>
    </Modal>
  </span>
</template>

<script>
import { http } from "app/v1/src/plugins/http";
import { getData } from "app/v1/src/utils/get";
import { required } from "vuelidate/lib/validators";
import Modal from "app/v1/src/components/Modal";

export default {
  components: {
    Modal
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isVisible: false,
      title: "Compartilhamento de NFCe's",
      share: {
        emails: "",
        ids: ""
      }
    };
  },

  validations: {
    share: {
      emails: {
        required
      }
    }
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },

    handleDownload(e) {
      if (this.$v.share.$invalid) {
        e.preventDefault();
      } else {
        const payload = {
          share_nfe: {
            emails: this.share.emails.split(","),
            nfce_id: this.id
          }
        };

        http
          .post("/app/share/customer_invoices", payload)
          .then(getData)
          .then(() => this.closeModal())
          .catch(() => Promise.reject("FAIL_IN_DOWNLOAD_NF"));
      }
    }
  }
};
</script>
