<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações do Percurso
    </h3>

    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="field required">
              <label class="label">
                <abbr title="necessário">*</abbr>
                Cidades de Carregamento
              </label>

              <div class="control">
                <span class="select is-medium is-fullwidth">
                  <select v-model="current.infMunCarrega">
                    <option
                      disabled
                      value=""
                    >Selecione uma cidade</option>
                    <option
                      v-for="(city, index) in cidadesCarrega"
                      :key="index"
                      :value="city.id"
                    >
                      {{ city.name_with_state }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>

          <div
            class="column is-narrow"
            style="align-self:flex-end;"
          >
            <a
              class="button is-medium"
              @click.prevent="addCity('infMunCarrega', current.infMunCarrega)"
            >
              + Adicionar
            </a>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="field required">
              <label class="label">
                <abbr title="necessário">*</abbr>
                Estados do Percurso
              </label>

              <div class="control">
                <span class="select is-medium is-fullwidth">
                  <select v-model="current.state_id">
                    <option
                      disabled
                      value=""
                    >Selecione um estado</option>
                    <option
                      v-for="(state, index) in estadosPercurso"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.uf }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>

          <div
            class="column is-narrow"
            style="align-self:flex-end;"
          >
            <a
              class="button is-medium"
              @click.prevent="addState(current.state_id)"
            >
              + Adicionar
            </a>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="field required">
              <label class="label">
                <abbr title="necessário">*</abbr>
                Cidades de Descarregamentos
              </label>

              <div class="control">
                <span class="select is-medium is-fullwidth">
                  <select v-model="current.infMunDescarga">
                    <option
                      disabled
                      value=""
                    >Selecione uma cidade</option>
                    <option
                      v-for="(city, index) in cidadesDescarga"
                      :key="index"
                      :value="city.id"
                    >
                      {{ city.name_with_state }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>

          <div
            class="column is-narrow"
            style="align-self:flex-end;"
          >
            <a
              class="button is-medium"
              @click.prevent="addCity('infMunDescarga', current.infMunDescarga)"
            >
              + Adicionar
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="router__list">
          <div
            v-for="(city, index) in infMunCarrega"
            :key="index"
            class="router__item"
          >
            <div class="router__item__name">
              {{ city.name_with_state }}
            </div>

            <a
              class="router__item__link has-text-danger"
              @click="destroyCity('infMunCarrega', city.code)"
            >
              Remover
            </a>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="router__list">
          <div
            v-for="(state, index) in infPercurso"
            :key="index"
            class="router__item"
          >
            <div class="router__item__name">
              {{ state.uf }}
            </div>

            <a
              class="router__item__link has-text-danger"
              @click="destroyState(state.uf)"
            >
              Remover
            </a>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="router__list">
          <div
            v-for="(city, index) in infMunDescarga"
            :key="index"
            class="router__item"
          >
            <div class="router__item__name">
              {{ city.name_with_state }}
            </div>

            <a
              class="router__item__link has-text-danger"
              @click="destroyCity('infMunDescarga', city.code)"
            >
              Remover
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mdfe: {
      type: Object,
      required: true,
    },

    cities: {
      type: Array,
      required: true,
    },

    states: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      current: {
        infMunCarrega: "",
        infMunDescarga: "",
        state_id: "",
      },
    };
  },

  computed: {
    cidadesCarrega: function () {
      const codes = this.mdfe.infMunCarrega.map((city) => city.cMunCarrega);
      return this.cities.filter((city) => !codes.includes(city.code));
    },

    cidadesDescarga: function () {
      const codes = this.mdfe.infMunDescarga.map((city) => city.cMunDescarga);
      return this.cities.filter((city) => !codes.includes(city.code));
    },

    estadosPercurso: function () {
      const ufs = this.mdfe.infPercurso.map((state) => state.UFPer);
      return this.states.filter((state) => !ufs.includes(state.uf));
    },

    infMunCarrega: function () {
      const codes = this.mdfe.infMunCarrega.map((city) => city.cMunCarrega);
      return codes.map((cityCode) => this.findCityByCode(cityCode));
    },

    infMunDescarga: function () {
      const codes = this.mdfe.infMunDescarga.map((city) => city.cMunDescarga);
      return codes.map((cityCode) => this.findCityByCode(cityCode));
    },

    infPercurso: function () {
      return this.mdfe.infPercurso.map((state) =>
        this.findStateByUf(state.UFPer)
      );
    },
  },

  methods: {
    addCity(type, cityId) {
      const city = this.findCityById(cityId);
      const cities = this.mdfe[type];
      this.mdfe[type] = [this.schema(type, city), ...cities];
      this.current[type] = "";
    },

    destroyCity(type, cityCode) {
      this.mdfe[type] = this.mdfe[type].filter((city) => {
        switch (type) {
          case "infMunCarrega":
            return city.cMunCarrega !== cityCode;
          case "infMunDescarga":
            return city.cMunDescarga !== cityCode;
        }
      });
    },

    addState(stateId) {
      const state = this.findStateById(stateId);
      const states = this.mdfe.infPercurso;
      this.mdfe.infPercurso = [{ UFPer: state.uf }, ...states];
      this.current.state_id = "";
    },

    destroyState(stateUf) {
      this.mdfe.infPercurso = this.mdfe.infPercurso.filter(
        (state) => state.UFPer !== stateUf
      );
    },

    findCityById(cityId) {
      return this.cities.find((city) => city.id === cityId);
    },

    findCityByCode(cityCode) {
      return this.cities.find((city) => city.code === cityCode);
    },

    findStateById(stateId) {
      return this.states.find((state) => state.id === stateId);
    },

    findStateByUf(stateUf) {
      return this.states.find((state) => state.uf === stateUf);
    },

    schema(type, city) {
      switch (type) {
        case "infMunCarrega":
          return {
            cMunCarrega: city.code,
            xMunCarrega: city.name,
          };
        case "infMunDescarga":
          return {
            cMunDescarga: city.code,
            xMunDescarga: city.name,
            infCTe: [],
            infNFe: [],
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.router__item {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: 0.5rem 0;
}
</style>
