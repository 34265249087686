<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações Adicionais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Natureza da Operação</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select
                v-model="nfe.natOp"
                :disabled="operationDisabled"
              >
                <option
                  v-for="(operation, index) in operations"
                  :key="index"
                  :value="operation"
                >
                  {{ operation }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Indicador de Pagamento</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="nfe.indPag">
                <option
                  v-for="(p, index) in indPayments"
                  :key="index"
                  :value="p.value"
                >
                  {{ p.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Meio de Pagamento</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select
                v-model="nfe.tPag"
                :disabled="indPaymentDisabled"
              >
                <option
                  v-for="(p, index) in typePayments"
                  :key="index"
                  :value="p.value"
                >
                  {{ p.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <div class="control">
            <label class="label">
              Observações
            </label>

            <textarea
              v-model="nfe.description"
              class="textarea"
              placeholder="Informe como seu cliente poderá pagar, os descontos, as taxas de juros e multas ou descreva outras informações que possam ser relevantes para você e seu cliente."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nfe: {
      type: Object,
      required: true
    },

    operations: {
      type: Array,
      required: true
    },

    indPaymentDisabled: {
      type: Boolean,
      default: false
    },

    operationDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      indPayments: [
        { value: '0', text: 'Pagamento à Vista' },
        { value: '1', text: 'Pagamento à Prazo' }
      ],

      typePayments: [
        { value: '01', text: 'Dinheiro' },
        { value: '02', text: 'Cheque' },
        { value: '03', text: 'Cartão de Crédito' },
        { value: '04', text: 'Cartão de Débito' },
        { value: '05', text: 'Crédito Loja' },
        { value: '10', text: 'Vale Alimentação' },
        { value: '11', text: 'Vale Refeição' },
        { value: '12', text: 'Vale Presente' },
        { value: '13', text: 'Vale Combustível' },
        { value: '15', text: 'Boleto Bancário' },
        { value: '90', text: 'Sem pagamento' },
        { value: '99', text: 'Outros' },
      ]
    };
  },
};
</script>
