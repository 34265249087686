<template lang="html">
  <span class="cancelar-nfe">
    <a
      class="button-invoices"
      title="Encerrar"
      @click.prevent="openModal"
    >
      <i class="fa fa-lock invoice-icon" />
    </a>

    <Modal
      title="Encerrar Nota Fiscal"
      save-button-text="Encerrar"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="handleSubmit"
    >
      <form @submit.prevent="handleSubmit">
        <div class="notification is-info">
          Informe a cidade de encerramento da MDFe.
        </div>

        <div class="columns">
          <div class="column">
            <div class="field required">
              <label class="label">
                <abbr title="necessário">*</abbr>
                Cidade de Encerramento
              </label>

              <div class="control">
                <span class="select is-medium is-fullwidth">
                  <select v-model="mdfe.closure.cMun">
                    <option
                      disabled
                      value=""
                    >Selecione uma cidade</option>
                    <option
                      v-for="(city, index) in cities"
                      :key="index"
                      :value="city.code"
                    >
                      {{ city.name_with_state }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </span>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';

import Modal from 'app/v1/src/components/Modal';

export default {

  components: {
    Modal
  },

  props: {
    id: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      cities: [],
      isVisible: false,
      mdfe: {
        closure: {
          cUF: '',
          cMun: ''
        }
      },
    };
  },

  validations: {
    mdfe: {
      closure: {
        required,
        cUF: { required },
        cMun: { required },
      },
    }
  },

  watch: {
    'mdfe.closure.cMun': function(val) {
      this.cities.find(city => {
        if (city.code == val) {
          this.mdfe.closure.cUF = city.state_code;
        }
      });
    },
  },

  mounted() {
    this.setCities();
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },

    handleSubmit(e) {
      if (this.$v.mdfe.$invalid) {
        e.preventDefault();
      } else {
        return this.submitClosure();
      }
    },

    submitClosure() {
      const { mdfe } = this;
      http.post(`/api/mdfes/${this.id}/closure`, {
        mdfe
      })
        .then(getData)
        .then(() => {
          this.closeModal();
          this.mdfe.closure.cUF = '';
          this.mdfe.closure.cMun = '';
        })
        .catch(() => Promise.reject('FAIL_IN_CLOSURE_INVOICE'));
    },

    setCities() {
      http.get('/api/cities')
        .then(getData)
        .then(data => this.cities = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_CITIES'));
    },
  }
};
</script>
