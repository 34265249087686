<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações da Carga
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label
            class="label"
            for="vCarga"
          >
            <abbr title="necessário">*</abbr>
            Valor da Carga
          </label>
          <div class="control">
            <CurrencyInput
              id="vCarga"
              v-model="cte.vCarga"
              classes="input is-medium"
              placeholder="R$ 1320,90"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="proPred"
          >
            <abbr title="necessário">*</abbr>
            Produto Predominante
          </label>
          <div class="control">
            <input
              id="proPred"
              v-model="cte.proPred"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="xOutCat"
          >
            Outras Características do Produto
          </label>
          <div class="control">
            <input
              id="xOutCat"
              v-model="cte.xOutCat"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <table class="table is-bordered">
          <thead>
            <tr>
              <th>* Cod. Unid. Medida</th>
              <th>* Tipo de Medida</th>
              <th>* Qtde da Carga</th>
              <th style="border:none" />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in cte.infQ"
              :key="index"
            >
              <td>
                <span class="select is-medium is-fullwidth">
                  <select v-model="item.cUnid">
                    <option
                      disabled
                      value=""
                    >Selecione</option>
                    <option
                      v-for="(cUnid, cUnidsIndex) in cUnids"
                      :key="cUnidsIndex"
                      :value="cUnid.value"
                    >
                      {{ cUnid.text }}
                    </option>
                  </select>
                </span>
              </td>

              <td>
                <input
                  v-model="item.tpMed"
                  type="text"
                  class="input products-table-input"
                  placeholder="Ex: UND"
                  :disabled="isItemDisabled(item)"
                >
              </td>

              <td>
                <input
                  v-model="item.qCarga"
                  type="text"
                  class="input products-table-input"
                  placeholder="Ex: 10000"
                  :disabled="isItemDisabled(item)"
                >
              </td>

              <td style="border:none">
                <a
                  class="button"
                  title="Remover Item"
                  @click.prevent="removeItem(index)"
                >
                  <i class="fa fa-times products-table-remove" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <a
          class="button"
          @click.prevent="addItem()"
        >+ Nova Linha</a>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import CurrencyInput from 'app/v1/src/components/CurrencyInput';

export default {
  components: {
    CurrencyInput,
  },

  props: {
    cte: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      cUnids: [
        { value: '00', text: 'M3' },
        { value: '01', text: 'KG' },
        { value: '02', text: 'TON' },
        { value: '03', text: 'UNIDADE' },
        { value: '04', text: 'LITROS' },
        { value: '05', text: 'MMBTU' },
      ],

      schema: {
        cUnid: '',
        tpMed: '',
        qCarga: '',
      },
    };
  },

  mounted() {
    this.addItem();
  },

  methods: {
    addItem() {
      this.cte.infQ.push(this.generateItemSchema());
    },

    removeItem(index) {
      this.cte.infQ.splice(index, 1);
    },

    generateItemSchema() {
      return Object.assign({}, this.schema);
    },

    isItemDisabled(item) {
      return isEmpty(item.cUnid);
    },
  }
};
</script>
