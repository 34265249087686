<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Documento
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">
            * Chave de Acesso
          </label>

          <div class="control">
            <input
              v-model="nfe.refNFe"
              type="string"
              class="input is-medium"
              placeholder="Preencher apenas com os 44 dígitos da NFe"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nfe: {
      type: Object,
      required: true
    }
  },
};
</script>
