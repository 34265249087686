<template lang="html">
  <div id="app">
    <form @submit.prevent="handleSubmit">
      <GeneralForm :cte="cte" />
      <FreightForm :cte="cte" />
      <DocForm :cte="cte" />
      <DocOtherForm :cte="cte" />
      <DocAntForm :cte="cte" />
      <AdditionalForm :cte="cte" />

      <div class="field required">
        <div class="control">
          <label class="label">Observações</label>

          <textarea
            v-model="cte.description"
            class="textarea"
            placeholder="Descreva para seu cliente informações que possam ser relevantes para este transporte."
          />
        </div>
      </div>

      <div class="control-button">
        <button
          type="submit"
          class="button is-primary is-large"
        >
          Emitir
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';

import GeneralForm from './Form/General';
import FreightForm from './Form/Freight';
import AdditionalForm from './Form/Additional';
import DocForm from './Form/Doc';
import DocAntForm from './Form/DocAnt';
import DocOtherForm from './Form/DocOtherForm';

export default {
  components: {
    GeneralForm,
    FreightForm,
    AdditionalForm,
    DocForm,
    DocAntForm,
    DocOtherForm,
  },

  data() {
    return {
      cte: {
        sender_id: '',
        receiver_id: '',
        taker_id: '',
        tpCTe: 0,
        tpServ: 0,
        taker_kind: 0,
        modal: '01',
        cfop: '',
        natOp: '',
        vCarga: 0,
        proPred: '',
        infQ: [],
        rntrc: '',
        vTPrest: 0,
        vRec: 0,
        description: '',
        infNFe: [],
        infOutros: [],
        emiDocAnt: []
      },
    };
  },

  validations: {
    cte: {
      sender_id: { required },
      receiver_id: { required },
      tpCTe: { required },
      tpServ: { required },
      taker_kind: { required },
      modal: { required },
      cfop: { required },
      natOp: { required },
      vCarga: { required },
      proPred: { required },
      rntrc: { required },
      vTPrest: { required },
      vRec: { required },
      infQ: {
        required,
        $each: {
          cUnid: { required },
          tpMed: { required },
          qCarga: { required },
        }
      }
    }
  },

  methods: {
    handleSubmit(e) {
      if (this.$v.cte.$invalid) {
        e.preventDefault();
      } else {
        return this.handleCreate();
      }
    },

    handleCreate() {
      const { cte } = this;
      http.post('/api/ctes', { cte })
        .then(getData)
        .then(() => Turbolinks.visit('/app/ctes'))
        .catch(() => Promise.reject('FAIL_IN_CREATE_CTE'));
    },
  }
};
</script>
