<template lang="html">
  <form class="supplier-form">
    <div class="field">
      <label
        class="label"
        for="supplier_person"
      >
        <abbr title="necessário">*</abbr>
        Tipo
      </label>

      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select
            id="supplier_person"
            v-model="supplier.person"
          >
            <option value="legal_entity">Pessoa Jurídica</option>
            <option value="individual">Pessoa Física</option>
          </select>
        </span>
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_name"
      >
        <abbr title="necessário">*</abbr>
        {{ supplierName }}
      </label>

      <div class="control">
        <input
          id="supplier_name"
          v-model="supplier.name"
          type="text"
          class="input is-medium"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_cpf_cnpj"
      >
        <abbr title="necessário">*</abbr>
        {{ supplierCpfCnpj }}
      </label>
      <div class="control">
        <input
          id="supplier_cpf_cnpj"
          v-model="supplier.cpf_cnpj"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_simple_opt"
      >
        <abbr title="necessário">*</abbr>
        Optante pelo Simples Nacional
      </label>
      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select
            id="supplier_simple_opt"
            v-model="supplier.simple_opt"
          >
            <option
              value="false"
              selected
            >Não</option>
            <option value="true">Sim</option>
          </select>
        </span>
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_state_taxpayer"
      >
        <abbr title="necessário">*</abbr>
        Indicador de Inscrição Estadual
      </label>
      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select
            id="supplier_state_taxpayer"
            v-model="supplier.state_taxpayer"
          >
            <option value="non_payer">Não contribuinte</option>
            <option value="payer">Contribuinte</option>
            <option value="free">Contribuinte isento</option>
          </select>
        </span>
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_state_registration"
      >Inscrição Estadual</label>
      <div class="control">
        <input
          id="supplier_state_registration"
          v-model="supplier.state_registration"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_town_registration"
      >Inscrição Municipal</label>
      <div class="control">
        <input
          id="supplier_town_registration"
          v-model="supplier.town_registration"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_address_city"
      >
        <abbr title="necessário">*</abbr>
        Cidade
      </label>
      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select
            id="supplier_address_city"
            v-model="supplier.address_attributes.city_id"
          >
            <option
              v-for="(city, index) in cities"
              :key="index"
              :value="city.id"
            >
              {{ city.name_with_state }}
            </option>
          </select>
        </span>
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_address_street"
      >
        <abbr title="necessário">*</abbr>
        Rua
      </label>
      <div class="control">
        <input
          id="supplier_address_street"
          v-model="supplier.address_attributes.street"
          type="text"
          class="input is-medium"
          placeholder="Apenas letras, sem pontuações"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_address_number"
      >
        <abbr title="necessário">*</abbr>
        Número
      </label>
      <div class="control">
        <input
          id="supplier_address_number"
          v-model="supplier.address_attributes.number"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_address_neighborhood"
      >
        <abbr title="necessário">*</abbr>
        Bairro
      </label>
      <div class="control">
        <input
          id="supplier_address_neighborhood"
          v-model="supplier.address_attributes.neighborhood"
          type="text"
          class="input is-medium"
          placeholder="Apenas letras, sem pontuações"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="supplier_address_postal_code"
      >
        <abbr title="necessário">*</abbr>
        CEP
      </label>
      <div class="control">
        <input
          id="supplier_address_postal_code"
          v-model="supplier.address_attributes.postal_code"
          type="text"
          class="input is-medium"
          placeholder="00000-000"
        >
      </div>
    </div>
  </form>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';

export default {
  data() {
    return {
      supplier: {
        person: 'legal_entity',
        name: '',
        cpf_cnpj: '',
        simple_opt: false,
        state_taxpayer: 'non_payer',
        state_registration: '',
        town_registration: '',
        address_attributes: {
          city_id: '',
          street: '',
          number: '',
          neighborhood: '',
          postal_code: '',
        }
      },

      cities: [],
    };
  },

  computed: {
    supplierName: function () {
      if(this.supplier.person === 'legal_entity') {
        return 'Razão Social';
      } else {
        return 'Nome';
      }
    },

    supplierCpfCnpj: function () {
      if(this.supplier.person === 'legal_entity') {
        return 'CNPJ';
      } else {
        return 'CPF';
      }
    }
  },

  mounted() {
    this.setCities();
  },

  methods: {
    setCities() {
      http.get('/api/cities')
        .then(getData)
        .then(data => this.cities = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_CITIES'));
    },

    handleSubmit() {
      return this.handleCreate();
    },

    handleCreate() {
      const { supplier } = this;
      return http.post('/api/suppliers', { supplier })
        .then(getData)
        .then(data => Promise.resolve(data))
        .catch(() => Promise.reject('FAIL_IN_CREATE_SUPPLIER'));
    }
  }
};
</script>
