<template lang="html">
  <div id="app">
    <form @submit.prevent="handleSubmit">
      <GeneralForm
        :mdfe="mdfe"
        :cities="cities"
      />
      <InfPagForm :mdfe="mdfe" />
      <RoutesForm
        :mdfe="mdfe"
        :cities="cities"
        :states="states"
      />
      <DischargeForm
        :mdfe="mdfe"
        :cities="cities"
      />
      <RodoForm :mdfe="mdfe" />
      <ConductorForm :mdfe="mdfe" />
      <ContractorForm :mdfe="mdfe" />
      <TotalForm :mdfe="mdfe" />
      <!-- <ProdPredForm :mdfe="mdfe" /> -->
      <AdditionalForm :mdfe="mdfe" />

      <div class="control-button">
        <button
          type="submit"
          class="button is-primary is-large"
        >
          Emitir
        </button>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable no-dupe-keys */

import { http } from "app/v1/src/plugins/http";
import { getData } from "app/v1/src/utils/get";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

import GeneralForm from "./Form/General";
import RoutesForm from "./Form/Routes";
import DischargeForm from "./Form/Discharge";
import TotalForm from "./Form/Total";
import RodoForm from "./Form/Rodo";
import ConductorForm from "./Form/Conductor";
import AdditionalForm from "./Form/Additional";
import ContractorForm from "./Form/Contractor";
import InfPagForm from "./Form/InfPag";
// import ProdPredForm from "./Form/ProdPred";

export default {
  components: {
    GeneralForm,
    RoutesForm,
    DischargeForm,
    TotalForm,
    RodoForm,
    ConductorForm,
    AdditionalForm,
    ContractorForm,
    InfPagForm,
    // ProdPredForm,
  },

  data() {
    return {
      cities: [],
      states: [],
      mdfe: {
        tpEmit: 1,
        tpEmis: 1,
        dhIniViagem: "",
        starts_city_id: "",
        ends_city_id: "",
        infMunCarrega: [],
        infMunDescarga: [],
        infPercurso: [],
        tot: {
          vCarga: 0,
          cUnid: "01",
          qCarga: 0,
        },
        rodo: {
          infANTT: {
            RNTRC: "",
            infContratante: [{ cpf_cnpj: "" }],
          },
          veicTracao: {
            placa: "",
            tara: "",
            tpRod: "01",
            tpCar: "00",
            UF: "",
            condutor: [
              {
                CPF: "",
                xNome: "",
              },
            ],
          },
        },
        compl: {
          infAdFisco: "",
          infCpl: "",
        },
        infPag: {
          xNome: "",
          CNPJ: "",
        },
      },
    };
  },

  validations: {
    mdfe: {
      tpEmit: { required },
      tpEmis: { required },
      dhIniViagem: { required },
      starts_city_id: { required },
      ends_city_id: { required },
      infMunCarrega: {
        required,
        minLength: minLength(1),
        $each: {
          cMunCarrega: { required },
          xMunCarrega: { required },
        },
      },
      infMunDescarga: {
        required,
        minLength: minLength(1),
        $each: {
          cMunDescarga: { required },
          xMunDescarga: { required },
          infCTe: {
            $each: {
              chCTe: {
                minLength: minLength(44),
              },
            },
          },
          infNFe: {
            $each: {
              chNFe: {
                minLength: minLength(44),
              },
            },
          },
        },
      },
      tot: {
        vCarga: { required },
        cUnid: { required },
        qCarga: { required },
      },
      rodo: {
        veicTracao: {
          UF: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(2),
          },
          placa: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(7),
          },
          tara: { required },
          tpCar: { required },
          tpRod: { required },
          condutor: {
            required,
            minLength: minLength(1),
            $each: {
              CPF: { required },
              xNome: { required },
            },
          },
        },
      },
    },
  },

  mounted() {
    this.setCities();
    this.setStates();
  },

  methods: {
    handleSubmit(e) {
      if (this.$v.mdfe.$invalid) {
        e.preventDefault();
      } else {
        return this.handleCreate();
      }
    },

    handleCreate() {
      const { mdfe } = this;
      http
        .post("/api/mdfes", { mdfe })
        .then(getData)
        .then(() => Turbolinks.visit("/app/mdfes"))
        .catch(() => Promise.reject("FAIL_IN_CREATE_MDFES"));
    },

    setCities() {
      http
        .get("/api/cities")
        .then(getData)
        .then((data) => (this.cities = data))
        .catch(() => Promise.reject("FAIL_IN_FETCH_CITIES"));
    },

    setStates() {
      http
        .get("/api/states")
        .then(getData)
        .then((data) => (this.states = data))
        .catch(() => Promise.reject("FAIL_IN_FETCH_STATES"));
    },
  },
};
</script>
