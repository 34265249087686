<template>
  <div class="content">
    <h3 class="eco-invoice-title">
      <span>NFe Referênciada</span>
      <span
        v-if="!showMore"
        class="cursor-pointer text-sm text-blue-600 select-none"
        @click="showMore = !showMore"
      >Mostrar mais</span>

      <span
        v-if="showMore"
        class="cursor-pointer text-sm text-blue-600 select-none"
        @click="showMore = !showMore"
      >Mostrar menos</span>
    </h3>


    <div :class="showMoreClass">
      <div class="columns">
        <div class="column">
          <div class="field required">
            <label class="label">
              <abbr>* UF</abbr>
            </label>

            <div class="control">
              <span class="select is-medium is-fullwidth">
                <select v-model="nfe.refNF.cUF">
                  <option
                    v-for="(state, index) in states"
                    :key="index"
                    :value="state.code"
                  >
                    {{ state.uf }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">* Ano e Mês da Emissão (AAMM)</label>

            <div class="control">
              <input
                v-model="nfe.refNF.AAMM"
                v-mask="'####'"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">* CPF/CNPJ</label>

            <div class="control">
              <input
                v-model="nfe.refNF.cpf_cnpj"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>
        
        <div class="column">
          <div class="field">
            <label class="label">IE</label>

            <div class="control">
              <input
                v-model="nfe.refNF.IE"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">* Modelo do Documento</label>

            <div class="control">
              <span class="select is-medium is-fullwidth">
                <select v-model="nfe.refNF.mod">
                  <option
                    v-for="(mod, index) in mods"
                    :key="index"
                    :value="mod.value"
                  >
                    {{ mod.text }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">* Série do Documento</label>

            <div class="control">
              <input
                v-model="nfe.refNF.serie"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">* Número do Documento</label>

            <div class="control">
              <input
                v-model="nfe.refNF.nNF"
                type="string"
                class="input is-medium"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask
  },

  props: {
    nfe: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      showMore: false,
      states: [],
      mods: [
        { value: '01', text: 'Nota Produto 01' },
        { value: '02', text: 'Nota Produto 02' },
        { value: '04', text: 'Nota Produtor 04' }
      ],
    };
  },

  computed: {
    showMoreClass: function () {
      return {
        'hidden': !this.showMore
      };
    }
  },
  
  mounted() {
    this.setStates();
  },
  
  methods: {
    setStates() {
      http.get('/api/states')
        .then(getData)
        .then(data => this.states = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_STATES'));
    }
  }
};
</script>
