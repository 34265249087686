<template lang="html">
  <div id="app">
    <form @submit.prevent="handleSubmit">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label
              class="label"
              for="name"
            >
              <abbr title="necessário">*</abbr>
              Nome
            </label>
            <div class="control">
              <input
                id="name"
                v-model="productTax.name"
                type="text"
                class="input is-medium"
              >
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field required">
            <label class="label">
              <abbr>* Tipo de ICMS</abbr>
            </label>

            <div class="control">
              <span class="select is-medium is-fullwidth">
                <select
                  v-model="productTax.icms.type"
                  @change="taxChange"
                >
                  <option
                    v-for="(value, key) in taxes"
                    :key="key"
                    :value="key"
                  >
                    {{ key }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div
            v-for="(value, key) in taxes[productTax.icms.type]"
            :key="key"
            class="field"
          >
            <label
              class="label"
              :for="key"
            >
              <abbr title="necessário">*</abbr>
              {{ value.label }}
            </label>
            <div class="control">
              <input
                :id="key"
                v-model="productTax.icms.data[key]"
                type="text"
                class="input is-medium"
                :placeholder="value.placeholder"
                :readonly="value.readonly"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="control-button">
        <button
          type="submit"
          class="button is-primary is-large"
        >
          Salvar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    productTax: {
      type: Object,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },
  },

  data() {
    return {
      taxes: {
        ICMSSN101: {
          CSOSN: {
            default: '101',
            label: 'CSOSN',
            placeholder: '101',
            readonly: true
          },
          pCredSN: {
            default: '',
            label: 'pCredSN',
            placeholder: '0.00',
            readonly: false
          },
          vCredICMSSN: {
            default: '',
            label: 'vCredICMSSN',
            placeholder: '0.00',
            readonly: false
          }
        },

        ICMSSN102: {
          CSOSN: {
            default: '',
            label: 'CSOSN',
            placeholder: '102, 103, 300 ou 400',
            readonly: false
          }
        },

        ICMSSN201: {
          CSOSN: {
            default: '201',
            label: 'CSOSN',
            placeholder: '201',
            readonly: true
          },
          modBCST: {
            default: '',
            label: 'modBCST',
            placeholder: '1',
            readonly: false
          },
          pMVAST: {
            default: '',
            label: 'pMVAST',
            placeholder: '0.00',
            readonly: false
          },
          pRedBCST: {
            default: '',
            label: 'pRedBCST',
            placeholder: '0.00',
            readonly: false
          },
          vBCST: {
            default: '',
            label: 'vBCST',
            placeholder: '0.00',
            readonly: false
          },
          pICMSST: {
            default: '',
            label: 'pICMSST',
            placeholder: '0.00',
            readonly: false
          },
          vICMSST: {
            default: '',
            label: 'vICMSST',
            placeholder: '0.00',
            readonly: false
          },
          vBCFCPST: {
            default: '',
            label: 'vBCFCPST',
            placeholder: '0.00',
            readonly: false
          },
          pFCPST: {
            default: '',
            label: 'pFCPST',
            placeholder: '2.00',
            readonly: false
          },
          vFCPST: {
            default: '',
            label: 'vFCPST',
            placeholder: '0.00',
            readonly: false
          },
          pCredSN: {
            default: '',
            label: 'pCredSN',
            placeholder: '0.00',
            readonly: false
          },
          vCredICMSSN: {
            default: '',
            label: 'vCredICMSSN',
            placeholder: '0.00',
            readonly: false
          },
        },

        ICMSSN202: {
          CSOSN: {
            default: '',
            label: 'CSOSN',
            placeholder: '202 ou 203',
            readonly: false
          },
          modBCST: {
            default: '',
            label: 'modBCST',
            placeholder: '1',
            readonly: false
          },
          pMVAST: {
            default: '',
            label: 'pMVAST',
            placeholder: '0.00',
            readonly: false
          },
          pRedBCST: {
            default: '',
            label: 'pRedBCST',
            placeholder: '0.00',
            readonly: false
          },
          vBCST: {
            default: '',
            label: 'vBCST',
            placeholder: '0.00',
            readonly: false
          },
          pICMSST: {
            default: '',
            label: 'pICMSST',
            placeholder: '0.00',
            readonly: false
          },
          vICMSST: {
            default: '',
            label: 'vICMSST',
            placeholder: '0.00',
            readonly: false
          },
          vBCFCPST: {
            default: '',
            label: 'vBCFCPST',
            placeholder: '0.00',
            readonly: false
          },
          pFCPST: {
            default: '',
            label: 'pFCPST',
            placeholder: '2.00',
            readonly: false
          },
          vFCPST: {
            default: '',
            label: 'vFCPST',
            placeholder: '0.00',
            readonly: false
          }
        },

        ICMSSN500: {
          CSOSN: {
            default: '500',
            label: 'CSOSN',
            placeholder: '500',
            readonly: true
          },
          vBCSTRet: {
            default: '',
            label: 'vBCSTRet',
            placeholder: '0.00',
            readonly: false
          },
          vICMSSTRet: {
            default: '',
            label: 'vICMSSTRet',
            placeholder: '0.00',
            readonly: false
          },
          pST: {
            default: '',
            label: 'pST',
            placeholder: '0.00',
            readonly: false
          },
          vBCFCPSTRet: {
            default: '',
            label: 'vBCFCPSTRet',
            placeholder: '0.00',
            readonly: false
          },
          pFCPSTRet: {
            default: '',
            label: 'pFCPSTRet',
            placeholder: '2.00',
            readonly: false
          },
          vFCPSTRet: {
            default: '',
            label: 'vFCPSTRet',
            placeholder: '0.00',
            readonly: false
          }
        },

        ICMSSN900: {
          CSOSN: {
            default: '900',
            label: 'CSOSN',
            placeholder: '900',
            readonly: true
          },
          modBC: {
            default: '',
            label: 'modBC',
            placeholder: '1',
            readonly: false
          },
          vBC: {
            default: '',
            label: 'vBC',
            placeholder: '0.00',
            readonly: false
          },
          pRedBC: {
            default: '',
            label: 'pRedBC',
            placeholder: '0.00',
            readonly: false
          },
          pICMS: {
            default: '',
            label: 'pICMS',
            placeholder: '0.00',
            readonly: false
          },
          vICMS: {
            default: '',
            label: 'vICMS',
            placeholder: '0.00',
            readonly: false
          },
          modBCST: {
            default: '',
            label: 'modBCST',
            placeholder: '1',
            readonly: false
          },
          pMVAST: {
            default: '',
            label: 'pMVAST',
            placeholder: '0.00',
            readonly: false
          },
          pRedBCST: {
            default: '',
            label: 'pRedBCST',
            placeholder: '0.00',
            readonly: false
          },
          vBCST: {
            default: '',
            label: 'vBCST',
            placeholder: '0.00',
            readonly: false
          },
          pICMSST: {
            default: '',
            label: 'pICMSST',
            placeholder: '0.00',
            readonly: false
          },
          vICMSST: {
            default: '',
            label: 'vICMSST',
            placeholder: '0.00',
            readonly: false
          },
          vBCFCPST: {
            default: '',
            label: 'vBCFCPST',
            placeholder: '0.00',
            readonly: false
          },
          pFCPST: {
            default: '',
            label: 'pFCPST',
            placeholder: '2.00',
            readonly: false
          },
          vFCPST: {
            default: '',
            label: 'vFCPST',
            placeholder: '0.00',
            readonly: false
          },
          pCredSN: {
            default: '',
            label: 'pCredSN',
            placeholder: '0.00',
            readonly: false
          },
          vCredICMSSN: {
            default: '',
            label: 'vCredICMSSN',
            placeholder: '0.00',
            readonly: false
          },
        },
      }
    };
  },

  methods: {
    taxChange() {
      this.productTax.icms.data = {};
      const tax = this.taxes[this.productTax.icms.type];
      Object.entries(tax).forEach(([key, value]) => {
        this.productTax.icms.data[key] = value.default;
      });
    }
  }
};
</script>
