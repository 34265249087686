<template lang="html">
  <section>
    <CustomerForm
      :nfe="product_invoice"
      :v="$v"
      :customers="customers"
    />

    <ProductForm :nfe="product_invoice" />

    <TransportForm :nfe="product_invoice" />

    <AdditionalForm
      :nfe="product_invoice"
      :operations="operations"
    />


    <div class="control-button eco-invoice-super-total">
      <button
        type="submit"
        class="button is-primary is-large"
        @click="handleSubmit"
      >
        Emitir
      </button>

      <div class="eco-invoice-total">
        Total Geral: {{ grandTotal | toCurrency }}
      </div>
    </div>
  </section>
</template>

<script>
import { isEmpty } from 'lodash';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';
import { toCurrency } from 'app/v1/src/utils/filters';

import CustomerForm from './Form/Customer';
import ProductForm from './Form/ProductListCart';
import AdditionalForm from './Form/Additional';
import TransportForm from './Form/Transport';

export default {

  components: {
    ProductForm,
    CustomerForm,
    AdditionalForm,
    TransportForm,
  },

  filters: {
    toCurrency
  },
  data() {
    return {
      product_invoice: {
        customer_id: '',
        invoiceable_id: '',
        invoiceable_type: 'Customer',
        kind: 'departure',
        total: 0,
        description: '',
        items_attributes: [],
        natOp: 'Venda',
        modFrete: '9',
        indPag: '0',
        tPag: '01',
        tpNF: '1',
        vFrete: 0,
        transporta: {
          xNome: '',
          IE: '',
          xEnder: '',
          xMun: '',
          UF: '',
          cpf_cnpj: ''
        }
      },

      customers: [],

      operations: [
        'Venda',
        'Vendas Diversas',
        'Transferência',
        'Remessa',
        'Industrialização',
        'Lançamentos Diversos',
        'Consignação',
        'Venda de Entrada Futura',
        'Devolução de Mercadoria Recebida para Conserto',
        'Retorno',
        'Remessa de Mercadorias',
        'Remessa para Conserto',
        'Outros'
      ]
    };
  },

  computed: {
    productsTotal: function() {
      return this.product_invoice.items_attributes.reduce((prev, item) => {
        return prev + (parseFloat(item.price) * parseFloat(item.amount));
      }, 0);
    },

    grandTotal: function () {
      return parseFloat(this.productsTotal) + parseFloat(this.product_invoice.vFrete);
    },
  },

  mounted() {
    this.setCustomers();
  },

  validations: {
    product_invoice: {
      invoiceable_id: { required },
      natOp: { required },
      modFrete: { required },
      items_attributes: {
        required,
        $each: {
          product_id: { required },
          amount: { required },
          unit: { required },
          cfop: { required },
          price: { required },
        }
      }
    }
  },

  methods: {
    handleSubmit(e) {
      const lastItem = this.product_invoice.items_attributes.slice(-1)[0];

      if(isEmpty(lastItem.selected)) {
        this.product_invoice.items_attributes.pop();
      }

      if (this.$v.product_invoice.$invalid) {
        e.preventDefault();
      } else {
        return this.handleCreateInvoice();
      }
    },

    handleCreateInvoice() {
      const { product_invoice } = this;
      http.post('/api/product_invoices', { product_invoice })
        .then(getData)
        .then(() => Turbolinks.visit('/app/nfes/outputs'))
        .catch(() => Promise.reject('FAIL_IN_CREATE_INVOICE'));
    },

    setCustomers() {
      http.get('/api/customers')
        .then(getData)
        .then(data => this.customers = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_CUSTOMERS'));
    },
  }
};
</script>
