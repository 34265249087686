<template lang="html">
  <tr>
    <td>{{ createdAt }}</td>
    <td>{{ log.event }}</td>
    <td>{{ log.message }}</td>
    <td>
      <p
        v-for="(detail, index) in log.details"
        :key="index"
      >
        {{ detail }}
      </p>
    </td>
  </tr>
</template>

<script>
import Moment from 'moment';

export default {
  props: {
    log: {
      type: Object,
      require: true,
      default: function() { return {}; }
    }
  },

  computed: {
    createdAt: function() {
      return Moment(this.log.created_at).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>
