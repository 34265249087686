<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Totais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Valor da Carga
          </label>

          <div class="control">
            <CurrencyInput
              v-model="mdfe.tot.vCarga"
              classes="input is-medium"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Quantidade de Carga
          </label>

          <div class="control">
            <NumberInput
              v-model="mdfe.tot.qCarga"
              classes="input is-medium"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Unidade
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="mdfe.tot.cUnid">
                <option
                  v-for="(cUnid, index) in cUnids"
                  :key="index"
                  :value="cUnid.value"
                >
                  {{ cUnid.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInput from 'app/v1/src/components/CurrencyInput';
import NumberInput from 'app/v1/src/components/NumberInput';

export default {
  components: {
    CurrencyInput,
    NumberInput,
  },

  props: {
    mdfe: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      cUnids: [{
        value: '01',
        text: 'KG'
      },
      {
        value: '02',
        text: 'TON'
      },
      ],
    };
  },
};
</script>
