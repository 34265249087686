<template lang="html">
  <div class="column is-narrow">
    <div class="field required">
      <label class="label">
        <abbr>* Operação</abbr>
      </label>

      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select v-model="nfe.tpNF">
            <option
              v-for="(tpNF, index) in tpNFs"
              :key="index"
              :value="tpNF.value"
            >
              {{ tpNF.text }}
            </option>
          </select>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nfe: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      tpNFs: [
        { value: '0', text: 'Entrada' },
        { value: '1', text: 'Saida' }
      ],
    };
  },
};
</script>
