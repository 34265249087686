<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações do Fornecedor
    </h3>

    <div class="columns">
      <slot />
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Selecione seu fornecedor</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="nfe.invoiceable_id">
                <option
                  disabled
                  value=""
                >Selecione um fornecedor</option>
                <option
                  v-for="(supplier, index) in suppliers"
                  :key="index"
                  :value="supplier.id"
                >
                  {{ supplier.name }} ({{ supplier.cpf_cnpj }})
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div
        class="column is-narrow"
        style="align-self:flex-end;"
      >
        <a
          class="button is-medium"
          @click.prevent="openModal()"
        >
          + Novo Fornecedor
        </a>
      </div>
    </div>

    <Modal
      v-if="isVisible"
      title="Novo fornecedor"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="addSupplier"
    >
      <SupplierForm ref="SupplierForm" />
    </Modal>
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import Modal from 'app/v1/src/components/Modal';
import SupplierForm from 'app/v1/src/Supplier/New';

export default {

  components: {
    Modal,
    SupplierForm,
  },

  props: {
    nfe: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      suppliers: [],
      isVisible: false,
    };
  },

  mounted() {
    this.setSuppliers();
  },

  methods: {
    setSuppliers() {
      http.get('/api/suppliers')
        .then(getData)
        .then(data => this.suppliers = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_SUPPLIERS'));
    },

    addSupplier() {
      this.$refs.SupplierForm.handleSubmit()
        .then(data => {
          this.suppliers.push(data);
          this.nfe.invoiceable_id = data.id;
          this.closeModal();
        })
        .catch(() => Promise.reject('FAIL_ADD_SUPPLIER'));
    },

    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },
  }
};
</script>
