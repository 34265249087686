<template lang="html">
  <div class="root">
    <a
      class="button is-outlined"
      :class="classButton"
      :title="title"
      @click="openModal"
    >
      <span class="icon is-small">
        <i class="fa fa-cloud-download-alt" />
      </span>
    </a>

    <Modal
      :title="title"
      save-button-text="Enviar"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="handleDownload"
    >
      <div class="notification is-warning">
        O link para download das notas fiscais será enviado para o e-mail abaixo em alguns minutos.
      </div>
      <form @submit.prevent="handleDownload">
        <div
          class="field"
          :class="{'has-error': $v.email.$error}"
        >
          <label class="label is-medium">E-mail</label>
          <div class="control">
            <input
              v-model.trim="email"
              class="input is-medium"
              :class="{'is-danger': $v.email.$error}"
              type="email"
              placeholder="E-mail"
              @blur="$v.email.$touch()"
            >
          </div>

          <span
            v-if="!$v.email.required"
            class="help is-danger"
          >
            Não pode ficar em branco.
          </span>

          <span
            v-if="!$v.email.email"
            class="help is-danger"
          >
            Não é um email válido.
          </span>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required, email } from 'vuelidate/lib/validators';
import Modal from 'app/v1/src/components/Modal';

export default {
  components: {
    Modal
  },

  props: {
    byPeriod: {
      type: String,
      required: true
    },

    byStatus: {
      type: String,
      required: true
    },

    byKind: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isVisible: false,
      email: ''
    };
  },


  validations: {
    email: {
      required,
      email
    }
  },

  computed: {
    classButton: function() {
      if (this.byStatus == 'authorized') {
        return 'is-success';
      } else {
        return 'is-warning';
      }
    },

    title: function() {
      if (this.byStatus == 'authorized') {
        return 'Download Mensal Notas Autorizadas';
      } else {
        return 'Download Mensal Notas Canceladas';
      }
    }
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },

    handleDownload(event) {
      if (this.$v.email.$invalid) {
        event.preventDefault();
      } else {
        const payload = {
          email: this.email,
          by_period: JSON.parse(this.byPeriod),
          by_status: [this.byStatus],
          by_kind: [this.byKind],
        };

        http.post('/api/product_invoices/download', payload)
          .then(getData)
          .then(() => this.closeModal())
          .catch(() => Promise.reject('FAIL_IN_DOWNLOAD_NF'));
      }
    }
  }
};
</script>
