<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Documentos (Nota Produto)
    </h3>

    <div class="columns">
      <div class="column">
        <table class="table is-bordered">
          <thead>
            <tr>
              <th>Chave de Acesso</th>
              <th style="border:none" />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in cte.infNFe"
              :key="index"
            >
              <td>
                <input
                  v-model="item.chave"
                  type="text"
                  class="input products-table-input"
                  placeholder="Preencher apenas com os 44 dígitos da NFe"
                >
              </td>

              <td style="border:none">
                <a
                  class="button"
                  title="Remover Item"
                  @click.prevent="removeItem(index)"
                >
                  <i class="fa fa-times products-table-remove" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <a
          class="button"
          @click.prevent="addItem()"
        >+ Nova Linha</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    cte: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      schema: {
        chave: '',
      },
    };
  },

  methods: {
    addItem() {
      this.cte.infNFe.push(this.generateItemSchema());
    },

    removeItem(index) {
      this.cte.infNFe.splice(index, 1);
    },

    generateItemSchema() {
      return Object.assign({}, this.schema);
    }
  }
};
</script>
