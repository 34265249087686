<template lang="html">
  <input
    ref="input"
    v-mask="'##/##/#### ##:##'"
    type="text"
    placeholder="DD/MM/YYYY HH:MM"
    :value="valueFormmatted"
    :disabled="disabled"
    :class="classes"
    @change="updateValue($event.target.value)"
  >
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask
  },

  props: {
    value: {
      type: [String, Number],
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classes: {
      type: String,
      default: 'input'
    }
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value);
    }
  }
};
</script>
