<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações Adicionais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Natureza da Operação</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select
                v-model="nfe.natOp"
                :disabled="operationDisabled"
              >
                <option
                  v-for="(operation, index) in operations"
                  :key="index"
                  :value="operation"
                >
                  {{ operation }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Indicador de Pagamento</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="nfe.indPag">
                <option
                  v-for="(p, index) in indPayments"
                  :key="index"
                  :value="p.value"
                >
                  {{ p.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Meio de Pagamento</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select
                v-model="nfe.tPag"
                :disabled="indPaymentDisabled"
              >
                <option
                  v-for="(p, index) in typePayments"
                  :key="index"
                  :value="p.value"
                >
                  {{ p.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <slot />
    </div>

    <div class="columns">
      <div class="column">
        <label class="label">
          <abbr>Bandeira do Cartão</abbr>
        </label>

        <div class="control">
          <span class="select is-medium is-fullwidth">
            <select
              v-model="nfe.tBand"
              :disabled="!mustHaveCardTag"
            >
              <option
                v-for="(p, index) in typeBrands"
                :key="index"
                :value="p.value"
              >
                {{ p.text }}
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="column">
        <label class="label">
          <abbr>Instituição de Pagamento</abbr>
        </label>

        <div class="control">
          <span class="select is-medium is-fullwidth">
            <select
              v-model="nfe.card_CNPJ"
              :disabled="!mustHaveCardTag"
            >
              <option
                v-for="(p, index) in typeCardCNPJs"
                :key="index"
                :value="p.value"
              >
                {{ p.text }}
              </option>
            </select>
          </span>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <div class="control">
            <label class="label">
              Observações
            </label>

            <textarea
              v-model="nfe.description"
              class="textarea"
              placeholder="Informe como seu cliente poderá pagar, os descontos, as taxas de juros e multas ou descreva outras informações que possam ser relevantes para você e seu cliente."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nfe: {
      type: Object,
      required: true
    },

    operations: {
      type: Array,
      required: true
    },

    indPaymentDisabled: {
      type: Boolean,
      default: false
    },

    operationDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    mustHaveCardTag: function () {
      return ['03', '04'].includes(this.nfe.tPag);
    }
  },

  data() {
    return {
      indPayments: [
        { value: '0', text: 'Pagamento à Vista' },
        { value: '1', text: 'Pagamento à Prazo' }
      ],

      typePayments: [
        { value: '01', text: 'Dinheiro' },
        { value: '02', text: 'Cheque' },
        { value: '03', text: 'Cartão de Crédito' },
        { value: '04', text: 'Cartão de Débito' },
        { value: '05', text: 'Crédito Loja' },
        { value: '10', text: 'Vale Alimentação' },
        { value: '11', text: 'Vale Refeição' },
        { value: '12', text: 'Vale Presente' },
        { value: '13', text: 'Vale Combustível' },
        { value: '15', text: 'Boleto Bancário' },
        { value: '16', text: 'Depósito Bancário' },
        { value: '17', text: 'Pagamento Instantâneo (PIX)' },
        { value: '18', text: 'Transferência bancária, Carteira Digital' },
        { value: '19', text: 'Programa de fidelidade, Cashback, Crédito Virtual' },
        { value: '99', text: 'Outros' },
      ],
      typeBrands: [
        { value: '01', text: 'Dinheiro' },
        { value: '01', text: 'Visa' },
        { value: '02', text: 'Mastercard' },
        { value: '03', text: 'American Express' },
        { value: '04', text: 'Sorocred' },
        { value: '05', text: 'Diners Club' },
        { value: '06', text: 'Elo' },
        { value: '07', text: 'Hipercard' },
        { value: '08', text: 'Aura' },
        { value: '09', text: 'Cabal' },
        { value: '99', text: 'Outros' },
      ],
      typeCardCNPJs: [
        { value: null, text: '' },
        { value: '92934215000106', text: 'Banrisul' },
        { value: '01027058000191', text: 'Cielo' },
        { value: '10440482000154', text: 'GetNet' },
        { value: '18189547000142', text: 'InfinitePay' },
        { value: '10573521000191', text: 'Mercado Pago' },
        { value: '08561701000101', text: 'PagSeguro' },
        { value: '01425787000104', text: 'Rede' },
        { value: '03106213000271', text: 'Sicredi' },
        { value: '16501555000157', text: 'Stone' },
      ],
    };
  },
};
</script>
