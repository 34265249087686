<template lang="html">
  <span class="log-nfce">
    <a
      class="button-invoices"
      title="Informações de log"
      @click.prevent="openModal"
    >
      <i class="fa fa-info invoice-icon" />
    </a>

    <modal
      title="Log"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :show-footer="false"
    >
      <table class="table is-striped -is-middle">
        <thead>
          <tr>
            <th>Data</th>
            <th>Evento</th>
            <th>Mensagem</th>
            <th>Detalhes</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(log, index) in reverseLogs"
            :key="index"
          >
            <td>{{ formatDate(log.created_at) }}</td>
            <td>{{ log.event }}</td>
            <td>{{ log.message }}</td>
            <td>{{ log.details || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </modal>
  </span>
</template>

<script>
import { reverse } from 'lodash';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import Moment from 'moment';
import Modal from 'app/v1/src/components/Modal';

export default {

  components: {
    Modal
  },

  props: {
    id: {
      type: String,
      require: true,
      default: ''
    }
  },

  data() {
    return {
      isVisible: false,
      logs: []
    };
  },

  computed: {
    reverseLogs: function () {
      return reverse(this.logs);
    }
  },

  methods: {
    openModal() {
      http.get(`/api/customer_invoices/${this.id}`)
        .then(getData)
        .then(data => {
          this.logs = data.logs;
          this.isVisible = true;
        })
        .catch(() => Promise.reject('FAIL_IN_FETCH_CUSTOMER_INVOICE'));
    },

    closeModal() {
      this.isVisible = false;
    },

    formatDate(date) {
      return Moment(date).format('DD/MM/YYYY HH:mm');
    }
  }
};
</script>
