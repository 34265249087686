<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações do Veículo
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label
            class="label"
            for="TAF"
          >
            TAF
          </label>
          <div class="control">
            <input
              id="TAF"
              v-model="cte.TAF"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="NroRegEstadual"
          >
            <abbr title="necessário">*</abbr>
            Nº Registro Estadual
          </label>
          <div class="control">
            <input
              id="NroRegEstadual"
              v-model="cte.NroRegEstadual"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="placa"
          >
            <abbr title="necessário">*</abbr>
            Placa
          </label>
          <div class="control">
            <input
              id="placa"
              v-model="cte.placa"
              type="text"
              class="input is-medium"
              placeholder="Ex: ABC1234"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="RENAVAM"
          >
            <abbr title="necessário">*</abbr>
            RENAVAM
          </label>
          <div class="control">
            <input
              id="RENAVAM"
              v-model="cte.RENAVAM"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="UF"
          >
            <abbr title="necessário">*</abbr>
            UF
          </label>
          <div class="control">
            <input
              id="UF"
              v-model="cte.UF"
              type="text"
              class="input is-medium"
              placeholder="Ex: RS"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cte: {
      type: Object,
      required: true,
    }
  },
};
</script>
