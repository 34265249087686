<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Documentos de Transporte Anterior (Dados da Transportadora Anterior)
    </h3>

    <div
      v-for="(item, index) in cte.emiDocAnt"
      :key="index"
      class="columns"
    >
      <div class="column">
        <table class="table is-bordered">
          <thead>
            <tr>
              <th>CNPJ</th>
              <th>IE</th>
              <th>UF</th>
              <th>Nome</th>
              <th style="border:none" />
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <input
                  v-model="item.CNPJ"
                  type="text"
                  class="input products-table-input"
                  placeholder="Apenas números"
                >
              </td>

              <td>
                <input
                  v-model="item.IE"
                  type="text"
                  class="input products-table-input"
                  placeholder="Apenas números"
                >
              </td>

              <td>
                <input
                  v-model="item.UF"
                  type="text"
                  class="input products-table-input"
                  placeholder="Ex: RS"
                >
              </td>

              <td>
                <input
                  v-model="item.xNome"
                  type="text"
                  class="input products-table-input"
                  placeholder="Nome da empresa"
                >
              </td>

              <td style="border:none">
                <a
                  class="button"
                  title="Remover Item"
                  @click.prevent="removeItem(index)"
                >
                  <i class="fa fa-times products-table-remove" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="table is-bordered">
          <thead>
            <tr>
              <th>Chave</th>
              <th style="border:none" />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(doc, idDocAntEleIndex) in item.idDocAntEle"
              :key="idDocAntEleIndex"
            >
              <td>
                <input
                  v-model="doc.chCTe"
                  type="text"
                  class="input products-table-input"
                  placeholder="Preencher apenas com os 44 dígitos da CTe anterior"
                >
              </td>

              <td style="border:none">
                <a
                  class="button"
                  title="Remover Item"
                  @click.prevent="removechCTe(index, idDocAntEleIndex)"
                >
                  <i class="fa fa-times products-table-remove" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <a
          class="button"
          @click.prevent="addchCTe(index)"
        >+ Nova Chave</a>
      </div>
    </div>
    <a
      class="button"
      @click.prevent="addItem()"
    >+ Nova Linha</a>
  </div>
</template>

<script>
export default {

  props: {
    cte: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      schema: {
        CNPJ: '',
        IE: '',
        UF: '',
        xNome: '',
        idDocAntEle: [{
          chCTe: ''
        }]
      },
    };
  },

  methods: {
    addItem() {
      this.cte.emiDocAnt.push(this.generateItemSchema());
    },

    removeItem(index) {
      this.cte.emiDocAnt.splice(index, 1);
    },

    addchCTe(index) {
      this.cte.emiDocAnt[index].idDocAntEle.push({ chCTe: '' });
    },

    removechCTe(emiDocAntindex, index) {
      this.cte.emiDocAnt[emiDocAntindex].idDocAntEle.splice(index, 1);
    },

    generateItemSchema() {
      return Object.assign({}, this.schema);
    }
  }
};
</script>
