<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações da Adicionais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>* Tipo de Modal</abbr>
          </label>

          <div class="control">
            <span class="select is-medium is-fullwidth">
              <select v-model="cte.modal">
                <option
                  v-for="(modal, index) in modals"
                  :key="index"
                  :value="modal.value"
                >
                  {{ modal.text }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="rntrc"
          >
            <abbr title="necessário">*</abbr>
            Modal Rodoviário (RNTRC)
          </label>
          <div class="control">
            <input
              id="rntrc"
              v-model="cte.rntrc"
              type="text"
              class="input is-medium"
              placeholder="Preencher com 8 digitos."
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="vTPrest"
          >
            <abbr title="necessário">*</abbr>
            Valor Total do Serviço
          </label>
          <div class="control">
            <CurrencyInput
              id="vTPrest"
              v-model="cte.vTPrest"
              classes="input is-medium"
              placeholder="R$ 100,30"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="vRec"
          >
            <abbr title="necessário">*</abbr>
            Valor a Receber
          </label>
          <div class="control">
            <CurrencyInput
              id="vRec"
              v-model="cte.vRec"
              classes="input is-medium"
              placeholder="R$ 100,30"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInput from 'app/v1/src/components/CurrencyInput';

export default {
  components: {
    CurrencyInput,
  },

  props: {
    cte: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modals: [
        { value: '01', text: 'Rodoviário' },
        { value: '02', text: 'Aéreo' },
        { value: '03', text: 'Aquaviário' },
        { value: '04', text: 'Ferroviário' },
        { value: '05', text: 'Dutoviário' },
        { value: '06', text: 'Multimodal' },
      ]
    };
  }
};
</script>
