<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Dados das Notas
    </h3>

    <div class="columns">
      <div class="column">
        <div class="router__wrapper">
          <div
            v-for="(city, index) in infMunDescarga"
            :key="index"
            class="router__list"
          >
            <div class="router__item">
              <div class="router__item__name">
                {{ city.name_with_state }}
              </div>

              <a
                class="button is-medium"
                @click="addCTe(index)"
              >
                + Adicionar CTe
              </a>

              <a
                class="button is-medium"
                @click="addNFe(index)"
              >
                + Adicionar NFe
              </a>
            </div>

            <div class="router__inputs">
              <div
                v-for="(CTeObj, infCTeIndex) in mdfe.infMunDescarga[index].infCTe"
                :key="infCTeIndex"
                class="control router__input"
              >
                <input
                  v-model="CTeObj.chCTe"
                  v-mask="'############################################'"
                  type="text"
                  class="input is-medium"
                  placeholder="Preencher apenas com os 44 dígitos da CTe"
                >
                <a
                  class="button is-medium is-danger"
                  @click="removeCTe(index, infCTeIndex)"
                >
                  Remover
                </a>
              </div>
            </div>

            <div class="router__inputs">
              <div
                v-for="(NFeObj, infNFeIndex) in mdfe.infMunDescarga[index].infNFe"
                :key="infNFeIndex"
                class="control router__input"
              >
                <input
                  v-model="NFeObj.chNFe"
                  v-mask="'############################################'"
                  type="text"
                  class="input is-medium"
                  placeholder="Preencher apenas com os 44 dígitos da NFe"
                >
                <a
                  class="button is-medium is-danger"
                  @click="removeNFe(index, infNFeIndex)"
                >
                  Remover
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  props: {
    mdfe: {
      type: Object,
      required: true,
    },

    cities: {
      type: Array,
      required: true,
    },
  },

  computed: {
    infMunDescarga: function () {
      const codes = this.mdfe.infMunDescarga.map((city) => city.cMunDescarga);
      return codes.map((cityCode) => this.findCityByCode(cityCode));
    },
  },

  methods: {
    addNFe(index) {
      const infNFe = this.mdfe.infMunDescarga[index].infNFe;
      this.mdfe.infMunDescarga[index].infNFe = [...infNFe, { chNFe: "" }];
    },

    addCTe(index) {
      const infCTe = this.mdfe.infMunDescarga[index].infCTe;
      this.mdfe.infMunDescarga[index].infCTe = [...infCTe, { chCTe: "" }];
    },

    removeCTe(DescargaIndex, CTeIndex) {
      this.mdfe.infMunDescarga[DescargaIndex].infCTe = this.mdfe.infMunDescarga[
        DescargaIndex
      ].infCTe.filter((el, index) => index !== CTeIndex);
    },

    removeNFe(DescargaIndex, infNFeIndex) {
      this.mdfe.infMunDescarga[DescargaIndex].infNFe = this.mdfe.infMunDescarga[
        DescargaIndex
      ].infNFe.filter((el, index) => index !== infNFeIndex);
    },

    findCityByCode(cityCode) {
      return this.cities.find((city) => city.code === cityCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.router__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.router__list {
  margin-bottom: 1rem;
}

.router__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  &__name {
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.router__input {
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
}
</style>
