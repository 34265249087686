<template lang="html">
  <div id="app">
    <Form
      :product-tax="product_tax"
      :handle-submit="handleSubmit"
    />
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';
import Form from './Form';

export default {
  components: {
    Form
  },

  data() {
    return {
      product_tax: {
        name: '',
        icms: {
          type: '',
          data: {}
        }
      },
    };
  },

  validations: {
    product_tax: {
      name: { required },
      icms: {
        required,
        type: { required },
        data: { required }
      }
    }
  },

  methods: {
    handleSubmit(e) {
      if (this.$v.product_tax.$invalid) {
        e.preventDefault();
      } else {
        return this.handleCreate();
      }
    },

    handleCreate() {
      const { product_tax } = this;
      http.post('/api/product_taxes', { product_tax })
        .then(getData)
        .then(() => Turbolinks.visit('/app/taxes'))
        .catch(() => Promise.reject('FAIL_IN_CREATE_PRODUCT_TAX'));
    },
  }
};
</script>
