<template lang="html">
  <span class="corrigir-nfe">
    <a
      class="button-invoices"
      title="Carta de Correção (CC-e)"
      @click.prevent="openModal"
    >
      <i class="fa fa-edit invoice-icon" />
    </a>

    <modal
      title="Carta de Correção (CC-e)"
      save-button-text="Emitir CCe"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="corrigirNfe"
    >
      <form @submit.prevent="corrigirNfe">
        <div class="field">
          <p class="control">
            <strong>Atenção:</strong> <br>
            1 - Variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço, quantidade, valor da operação (para estes casos deverá ser utilizada NF-e Complementar);
            <br>2 - Correção de dados cadastrais que implique mudança do remetente ou do destinatário;
            <br>3 - Data de emissão da NF-e ou a data de saída da mercadoria.
          </p>
          <label class="label">Justificativa</label>
          <p class="control">
            <textarea
              v-model="product_invoice.correction"
              class="textarea"
              placeholder="Ex: Nota fiscal emitida incorretamente..."
            />
          </p>
        </div>
      </form>
    </modal>
  </span>
</template>

<script>
import { isEmpty } from 'lodash';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import Modal from 'app/v1/src/components/Modal';

export default {
  components: {
    Modal
  },

  props: {
    id: {
      type: String,
      require: true,
      default: ''
    }
  },

  data() {
    return {
      isVisible: false,
      product_invoice: {
        correction: ''
      },
    };
  },

  methods: {
    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },

    hasCorrection() {
      return !isEmpty(this.product_invoice.correction);
    },

    greaterCorrection() {
      return this.product_invoice.correction.length > 20;
    },

    corrigirNfe() {
      if(this.hasCorrection() && this.greaterCorrection()) {
        this.handleCorrigirNfe();
      }
    },

    handleCorrigirNfe() {
      const { product_invoice } = this;
      http.post(`/api/product_invoices/${this.id}/cce`, { product_invoice })
        .then(getData)
        .then(() => {
          this.closeModal();
          this.product_invoice.correction = '';
        })
        .catch(() => Promise.reject('FAIL_IN_CCE_INVOICE'));
    }
  }
};
</script>
