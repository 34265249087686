<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Dados do Condutor
      <a
        class="button is-medium"
        @click="addConductor()"
      >
        + Adicionar
      </a>
    </h3>

    <div
      v-for="(condutor, index) in mdfe.rodo.veicTracao.condutor"
      :key="index"
      class="columns"
    >
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            Nome do Condutor
          </label>

          <div class="control">
            <input
              v-model="condutor.xNome"
              type="text"
              placeholder="Nome do Condutor"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr title="necessário">*</abbr>
            CPF do Condutor
          </label>

          <div class="control">
            <input
              v-model="condutor.CPF"
              v-mask="'###########'"
              type="text"
              placeholder="00000000000"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div
        class="column is-narrow"
        style="align-self: flex-end;"
      >
        <a
          class="button is-medium is-danger"
          @click="removeConductor(index)"
        >
          Remover
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {

  directives: {
    mask
  },
  props: {
    mdfe: {
      type: Object,
      required: true
    },
  },

  methods: {
    addConductor(){
      const conductors = this.mdfe.rodo.veicTracao.condutor;
      this.mdfe.rodo.veicTracao.condutor = [{ CPF: '', xNome: '' }, ...conductors];
    },

    removeConductor(conductorIndex) {
      this.mdfe.rodo.veicTracao.condutor =
        this.mdfe.rodo.veicTracao.condutor
          .filter((el, index) => index !== conductorIndex);
    },
  }
};
</script>
