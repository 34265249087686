<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações do Pagamento do Frete
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label
            class="label"
            for="Nome"
          >
            Nome
          </label>
          <div class="control">
            <input
              v-model="mdfe.infPag.xNome"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label
            class="label"
            for="CNPJ"
          >
            CNPJ
          </label>
          <div class="control">
            <input
              v-model="mdfe.infPag.CNPJ"
              type="text"
              class="input is-medium"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mdfe: {
      type: Object,
      required: true,
    },
  },
};
</script>
