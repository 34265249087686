<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Outros Documentos
    </h3>

    <div class="columns">
      <div class="column">
        <table class="table is-bordered">
          <thead>
            <tr>
              <th>Tipo de Documento</th>
              <th>Descrição do documento</th>
              <th>Valor do documento</th>
              <th style="border:none" />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in cte.infOutros"
              :key="index"
            >
              <td>
                <span class="select is-medium is-fullwidth">
                  <select v-model="item.tpDoc">
                    <option
                      v-for="(doc, docIndex) in docs"
                      :key="docIndex"
                      :value="doc.value"
                    >
                      {{ doc.text }}
                    </option>
                  </select>
                </span>
              </td>

              <td>
                <input
                  v-model="item.descOutros"
                  type="text"
                  class="input products-table-input"
                  placeholder="Descrição do documento"
                >
              </td>

              <td>
                <input
                  v-model="item.vDocFisc"
                  type="text"
                  class="input products-table-input"
                  placeholder="1000.30"
                >
              </td>

              <td style="border:none">
                <a
                  class="button"
                  title="Remover Item"
                  @click.prevent="removeItem(index)"
                >
                  <i class="fa fa-times products-table-remove" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <a
          class="button"
          @click.prevent="addItem()"
        >+ Nova Linha</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    cte: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      docs: [
        { value: '00', text: 'Declaração' },
        { value: '10', text: 'Dutoviário' },
        { value: '59', text: 'CF-e SAT' },
        { value: '65', text: 'NFC-e' },
        { value: '99', text: 'Outros' },
      ],
      schema: {
        tpDoc: '00',
        descOutros: '',
        vDocFisc: '',
      },
    };
  },

  methods: {
    addItem() {
      this.cte.infOutros.push(this.generateItemSchema());
    },

    removeItem(index) {
      this.cte.infOutros.splice(index, 1);
    },

    generateItemSchema() {
      return Object.assign({}, this.schema);
    }
  }
};
</script>
